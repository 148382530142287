<!--
- ToolDrawerTitle
-- Heading of every Tool Drawer
-- pass various options to control the back button or side menu trigger
-->

<template>
	<h1 class="clearfix tool-drawer-title">
		<!-- closeable X with a route name to open instead -->
		<router-link
			v-if="closeToRouteName"
			class="link-icon"
			:to="{
				name: closeToRouteName
			}">
			<span class="svg bi-x-lg" title="Close" />
		</router-link>
		
		<!-- closeable X emits a show-warning event to the parent -->
		<a v-else-if="isCloseWarning" href="#" class="link-icon" @click.prevent="$emit('show-warning')">
			<span class="svg bi-x-lg" title="Close" />
		</a>

		<!-- back arrow with a route object to open instead -->
		<router-link v-else-if="closeToRoute" :to="closeToRoute" class="link-icon">
			<span class="svg bi-chevron-left" title="Back" />
		</router-link>

		<!-- back arrow - use $router go to step backwards -->
		<a v-else-if="isBrowserBack" href="#" @click.prevent="btnGoBack" class="link-icon">
			<span class="svg bi-chevron-left" title="Back" />
		</a>

		<!-- else: Side Menu Trigger -->
		<a v-else-if="!isTitleOnly" href="#" @click.prevent="openSideMenu" class="link-icon" data-bs-toggle="tooltip" data-bs-title="Open Menu" data-bs-placement="left">
			<span class="svg bi-list" title="Open Menu" />
		</a>
		
		<!-- display the drawer title or the reference type name -->
		<span v-html="title" />
		
		<btn-close v-if="!isTitleOnly" />
	</h1>
</template>

<script>
import BtnClose from './BtnClose';

export default {
	name: 'ToolDrawerTitle',
	props:{
		closeToRoute:{
			type: Object,
		},
		closeToRouteName:{
			type: String,
		},
		isBrowserBack:{
			default: false,
			type: Boolean,
		},
		isCloseWarning:{
			default: false,
			type: Boolean,
		},
		isTitleOnly:{
			default: false,
			type: Boolean,
		},
		title: {
			type: String,
		},
	},
	methods: {
		btnGoBack(){
			this.$router.go(-1);
		},
		openSideMenu(){
			this.$store.commit('paperEdit/toolDrawer/IS_SIDEMENU_OPEN', true);
		},
	},
	components: {
		BtnClose,
	}
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/_vars_perrla.scss";
	
	$tool-drawer-title-height: 34px !default;
	@include color-mode(dark) {
		.tool-drawer-title{	
			color: $dark-body-color !important;
			a.link-icon{
				color: $dark-body-color;
			}
		}
	}

	.tool-drawer-title{
		color: $brand-500 !important;
		font-size: 1.6rem;
		line-height: $tool-drawer-title-height;
		font-weight: $font-weight-bold;

		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden; 

		height: $tool-drawer-title-height;

		a.link-icon{
			color: $body-color;
			float: left;
			line-height: 1;

			&:hover {
				text-decoration: none;
			}

			span.svg {
				line-height: $tool-drawer-title-height;

				&.bi-chevron-left {
					font-size: 1rem;
					padding-right: 0.5rem;
				}
				&.bi-list{
					font-size: 1.5rem;
					margin-right: 0.25rem;
				}
				&.bi-x-lg{
					font-size: 1.5rem;
					margin-right: 0.25rem;
				}
			}
		}
	}
</style>
