import config from '@/config';
import ReferenceList from '@/views/ReferenceList';
import ReferenceListCreateReferenceStep1 from '@/views/ReferenceList/modals/CreateReferenceStep1.vue';
import ReferenceListCreateReferenceStep2 from '@/views/ReferenceList/modals/CreateReferenceStep2.vue';
import ReferenceListCreateReferenceStep2ThirdParty from '@/views/ReferenceList/modals/CreateReferenceStep2ThirdParty.vue';
import ReferenceListCreateReferenceStep3 from '@/views/ReferenceList/modals/CreateReferenceStep3.vue';
import ReferenceListCreateReferenceStep3ThirdParty from '@/views/ReferenceList/modals/CreateReferenceStep3ThirdParty.vue';
import ReferenceListCreateReferenceStep4ThirdParty from '@/views/ReferenceList/modals/CreateReferenceStep4ThirdParty.vue';
import ReferenceListLegacyConversion from '@/views/ReferenceList/modals/LegacyConversion.vue';
import ReferenceListLegacyConversionStep2 from '@/views/ReferenceList/modals/LegacyConversion2.vue';

import ReferenceEdit from '@/views/ReferenceEdit';
import ReferenceTabCitations from '@/views/ReferenceEdit/TabCitations.vue';
import ReferenceTabDetails from '@/views/ReferenceEdit/TabDetails.vue';
import ReferenceTabPapers from '@/views/ReferenceEdit/TabPapers.vue';
import ReferenceTabResearchNotes from '@/views/ReferenceEdit/TabResearch.vue';

export default [
	{
		name: 'ReferenceList',
		component: ReferenceList,
		path: '/references',
		meta: {
			layout: config.enums.Layout.PO,
			module: config.enums.Module.REFERENCE_LIST,
			tab: config.enums.Tab._
		},
		children: [
			{
				name: 'ReferenceListCreateReferenceStep1',
				component: ReferenceListCreateReferenceStep1,
				path: 'create-1/:openReferenceTab',
				props: true,
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_LIST,
					tab: config.enums.Tab._
				},
			},
			{
				name: 'ReferenceListCreateReferenceStep2',
				component: ReferenceListCreateReferenceStep2,
				path: 'create-2/:openReferenceTab/:referenceTypeSlug',
				props: true,
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_LIST,
					tab: config.enums.Tab._
				},
			},
			{
				name: 'ReferenceListCreateReferenceStep2ThirdParty',
				component: ReferenceListCreateReferenceStep2ThirdParty,
				path: 'create-2-3plu',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_LIST,
					tab: config.enums.Tab._
				},
			},
			{
				name: 'ReferenceListCreateReferenceStep3',
				component: ReferenceListCreateReferenceStep3,
				path: 'create-3',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_LIST,
					tab: config.enums.Tab._
				},
			},
			{
				name: 'ReferenceListCreateReferenceStep3ThirdParty',
				component: ReferenceListCreateReferenceStep3ThirdParty,
				path: 'create-3-3plu/:referenceTypeSlug',
				props: true,
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_LIST,
					tab: config.enums.Tab._
				},
			},
			{
				name: 'ReferenceListCreateReferenceStep4ThirdParty',
				component: ReferenceListCreateReferenceStep4ThirdParty,
				path: 'create-4-3plu',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_LIST,
					tab: config.enums.Tab._
				},
			},
			{
				name: 'ReferenceListLegacyConversion',
				component: ReferenceListLegacyConversion,
				path: 'convert-reference',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_LIST,
					tab: config.enums.Tab._
				},
			},
			{
				name: 'ReferenceListLegacyConversionStep2',
				component: ReferenceListLegacyConversionStep2,
				path: 'convert-reference-2',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_LIST,
					tab: config.enums.Tab._
				},
			},
			
		],
	},//e:references
	{
		name: 'ReferenceEdit',
		component: ReferenceEdit,
		path: '/reference-edit/:referenceUid',
		props: true,
		meta: {
			layout: config.enums.Layout.PO,
			module: config.enums.Module.REFERENCE_EDIT,
			tab: config.enums.Tab._
		},
		children: [
			{
				name: 'ReferenceTabCitations',
				component: ReferenceTabCitations,
				path: 'citations',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_EDIT,
					tab: config.enums.Tab.CITATIONS
				},
			},
			{
				name: 'ReferenceTabDetails',
				component: ReferenceTabDetails,
				path: 'details',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_EDIT,
					tab: config.enums.Tab.DETAILS
				},
			},
			{
				name: 'ReferenceTabPapers',
				component: ReferenceTabPapers,
				path: 'papers',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_EDIT,
					tab: config.enums.Tab.PAPERS
				},
			},
			{
				name: 'ReferenceTabResearchNotes',
				component: ReferenceTabResearchNotes,
				path: 'research-notes',
				meta: {
					layout: config.enums.Layout.PO,
					module: config.enums.Module.REFERENCE_EDIT,
					tab: config.enums.Tab.RESEARCH
				},
			},
		]
	},
]