// ReferenceList and ReferenceEdit
const maxRecentReferenceTypes = 5;

import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';

export default {
	namespaced: true,

	state: {
		// local storage states
		recentTypeIds: [],		// array: contains a list of ids for use with Recent References Tab on create
		updateAfterSync: [],	// array of references to update (if refs change in another platform)
	
		// non local storage states
		displayFormatVersionId:	0,	// enum, this is a local change when the user has a preferredFormatVersionID but wants to instantly see their ref output in a different format
	},
	
	mutations: {
		ADD_TO_UPDATE_AFTER_SYNC(state, newReference){
			state.updateAfterSync.push(newReference);
		},
		RESET_UPDATE_AFTER_SYNC(state){
			state.updateAfterSync = [];
		},
		CLEAR_RECENT_TYPE_IDS(state){
			state.recentTypeIds = [];
		},
		// CLEAR_UPDATE_AFTER_SYNC(state){
		// 	state.updateAfterSync = [];
		// },
		RECENT_TYPE_IDS_ADD(state, refTypeId){
			// 1: trim the array down to the max number
			if(state.recentTypeIds.length > maxRecentReferenceTypes - 1){
				state.recentTypeIds = state.recentTypeIds.slice(0, maxRecentReferenceTypes - 1);
			}

			// 2: push the new type 
			if(!state.recentTypeIds.includes(refTypeId)){
				state.recentTypeIds.push(refTypeId);
			}
		},
		SET_DISPLAY_FORMAT_VERSION_ID(state, value){
			state.displayFormatVersionId = value;
		},
	},
}
