<!--
- Login
-- normal login
-- clean this up later since it's a near duplicate of LoginToAccount.vue
-->

<template>
	<div class="layout-pcom">
		<section class="section-text pt-0" style="background: url('https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/pencil-bg.png') repeat #fff;">
			<main class="container-text container-text-login-box-wrp mt-0 pt-3">

				<div class="white-inset login-box-wrp">
					<div class="inset-body">
						<img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png" width="140px" class="mx-auto d-block" role="presentation" />

						<div class="text-center">
							<h1>PERRLA</h1>
							<p>Write perfect papers from anywhere.</p>
							<h2>
								Log in to your account
							</h2>
						</div>

						<div class="alert alert-danger" v-if="!$isLive">
							<p class="fw-bold p-2">
								This is testing environment, to log in please visit: <a href="https://www.perrlacomplete.com/Public/#/login">https://www.perrlacomplete.com/Public/#/login</a>
							</p>
						</div>

						<form>
							<div class="alert alert-danger" v-if="isLoginError">
								The email or password provided is incorrect
							</div>
							
							<form-input
								label="Email"
								type="email"
								v-model="email"
								:form-id="zFormId"
								:validate-enum="$enums.Validation.EMAIL_REQUIRED" />
								
							<form-password
								label="Password"
								v-model="password"
								:form-id="zFormId"
								:validate-enum="$enums.Validation.REQUIRED" />

							<p class="link-forgot-password">
								<router-link
									:to="{
										name: 'ForgotPassword'
									}"
									class="hover-underline">
									Forgot password?
								</router-link>
							</p>

							<div class="form-group">
								<div class="d-grid gap-2">
									<button
										class="btn btn-success"	
										type="submit"
										@click.prevent="btnLogin">
										Log In
									</button>

									<router-link
										:to="{
											name: 'FreeTrialStart'
										}"
										class="btn btn-outline-primary btn-block">
										Start a Free Trial
									</router-link>

									<!-- <a href="../Accounts/FreeTrial" class="btn btn-outline-primary btn-block">Start a Free Trial</a> -->
								</div>
							</div>
						</form>

					</div><!--inset-body-->
				</div><!--white-inset-->
			</main>
		</section>

		<section class="section-text">
			<main class="container-text">
				<h1 class="text-center">
					Write in Word or on the web - it's your choice.
				</h1>

				<p>Every PERRLA subscription includes <strong>PERRLA&nbsp;for Word</strong>&nbsp;&amp;&nbsp;<strong>PERRLA&nbsp;Online</strong>&nbsp;so you can write your papers however you feel comfortable.</p>

				<p>
					Not sure which version of PERRLA to use?
					<a href="https://perrla.com/#/perrla-vs-word">Click here to learn which one is right for you.</a>
				</p>

				<p>
					<strong>
						<a href="https://perrla.com/#/perrla-for-word">PERRLA for Word</a>
					</strong> 
					works directly with Microsoft Word to handle every part of APA &amp; MLA formatting for you. Available for Windows &amp; Mac.
				</p>
				<p>
					<strong><a href="https://perrla.com/#/perrla-online">PERRLA Online</a></strong> 
					lets you write your papers from anywhere with our web-based editor. Then, download them as Word documents to turn in.
				</p>
			</main>
		</section>

		<div class="col-12 mx-auto mt-5">
			<h1 class="text-center">
				Every PERRLA subscription includes PERRLA Online & PERRLA for Word!
			</h1>
		</div><!--col-->

		<price-grid />

		<!-- Testimonial Slideshow -->
		<div class="testimonials-wrp d-none d-lg-block hero hero-dark text-center" style="background-image: linear-gradient(rgba(42, 80, 112, 0.43), rgba(42, 80, 112, 0.43)), url('/static/images/blue-icons.png'); background-position: 0px 0px, 50% 50%; background-size: auto, 200px; background-color: rgb(42, 80, 112);">
			<div class="container">
				<h1>Don't just take our word for it</h1>
				<p class="mb-4">
					Check out some actual reviews from students like you.
				</p>

				<transition name="route-fade" mode="out-in">
					<div class="slide" v-if="currentSlide === 1" key="slide1">
						<div class="quote-wrp">
							<testimonial-row :pull-index="0" />
							<testimonial-row :pull-index="1" />
							<testimonial-row :pull-index="2" :is-last="true" />
						</div><!--quote-wrp-->
					</div><!--slide-->

					<div class="slide" v-else-if="currentSlide === 2" key="slide2">
						<div class="quote-wrp">
							<testimonial-row :pull-index="3" />
							<testimonial-row :pull-index="4" />
							<testimonial-row :pull-index="5" :is-last="true" />
						</div><!--quote-wrp-->
					</div><!--slide-->

					<div class="slide" v-else-if="currentSlide === 3" key="slide3">
						<div class="quote-wrp">
							<testimonial-row :pull-index="6" />
							<testimonial-row :pull-index="7" />
							<testimonial-row :pull-index="8" :is-last="true" />
						</div><!--quote-wrp-->
					</div><!--slide-->

					<div class="slide" v-else-if="currentSlide === 4" key="slide4">
						<div class="quote-wrp">
							<testimonial-row :pull-index="9" />
							<testimonial-row :pull-index="10" />
							<testimonial-row :pull-index="11" :is-last="true" />
						</div><!--quote-wrp-->
					</div><!--slide-->

					<div class="slide" v-else-if="currentSlide === 5" key="slide5">
						<div class="quote-wrp">
							<testimonial-row :pull-index="12" />
							<testimonial-row :pull-index="13" />
							<testimonial-row :pull-index="14" :is-last="true" />
						</div><!--quote-wrp-->
					</div><!--slide-->

				</transition>

			</div><!--container-->
		</div><!--testimonials-wrp-->

		<modal-trouble-logging-in />

	</div><!--layout-pcom-->
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import AS_StoreSyncData from '@/services/app/storeSyncData';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import FormPassword from '@/components/form/Password';
import PriceGrid from '@/components/PriceGrid';
import TestimonialRow from '@/components/TestimonialRow';
import ModalTroubleLoggingIn from '@/views/Login/modals/TroubleLoggingIn';

const MAX_LOGIN_ATTEMPTS_BEFORE_SHOWING_MESSAGE = 3;

export default {
	name: 'LoginView',
	data() {
		return {
			currentLoginAttempt: 0,
			currentSlide: 1,
			email: (config.isDev) ? 'riley@perrla.com' : '',
			// email: '',
			isLoginError: false,
			password: (config.isDev) ? 'perrla' : '',
			// password: '',
			maxSlides: 5,
			stoSlide: null,	// timer
			zFormId: 'formLogin',
		}
	},
	methods: {
		btnLogin(){
			this.currentLoginAttempt++;
			this.isLoginError = false;

			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');

				// 1 - get an access token - this is a real login now
				ApiS_MakeCall({
					body: 'client_id=complete&grant_type=password&username=' + encodeURIComponent(this.email) + '&password=' + encodeURIComponent(this.password),
					contentType: 'application/x-www-form-urlencoded',
					ignoreAuth: true,
					isHardUrl: true,
					method: 'POST',
					responseType: 'json',
					url: (config.isLive) ? 'https://auth.perrla.com/connect/token' : 'https://testauth.perrla.com/connect/token',
				}).then((responseData)=>{
					if(!config.isLive){
						console.log('auth responseData');
						console.log(responseData);
					}

					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					// 2 - store the token in vuex
					this.$store.commit('api/SET_TOKEN', responseData.access_token);

					// call sync now that will handle account expiration problems
					AS_SyncData().then(()=>{
						// 3 - local development can't acccess the MVC project

						if(config.isDev){
							// 3a - set items manually and go to the dashboard
							this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);

							this.$router.push({
								name:"Dashboard"
							}).catch(()=>{});

						} else {
							// 3b - pass this token back to an MVC project 
							ApiS_MakeCall({
								method: 'GET',
								outsideSpa: true,
								responseType: 'json',
								url: 'Forms/Login',
							}).then((responseData2)=>{
								if(!config.isLive){
									console.log('Forms/Login responseData2');
									console.log(responseData2);
								}
								
								AS_StoreSyncData(responseData2).then(()=>{
									// console.log('this.$store.state.customer.expirationDate');
									// console.log(this.$store.state.customer.expirationDate);

									if(this.$store.state.customer.expirationDate && (new Date(this.$store.state.customer.expirationDate) < config.today)){
										// Account Expired
										window.location = config.appUrl + '/Public/#/settings/my-profile';

									// // } else if(responseData2.customer.isCancelled){
									// 	// Account Canceled
									// 	// window.location = config.appUrl + '/App/#/my-profile';

									}  else {
										// Account is Fine

										// are there any redirects stored i should go to instead?
										if(this.$store.state.redirectToAfterLogin === ''){
											// i have to use a hard redirect here to get from Public to App
											window.location = config.appUrl + '/App/#/dashboard';
										} else {
											// use the redirect
											let tempRedirectTo = this.$store.state.redirectToAfterLogin;
											this.$store.commit('SET_REDIRECT_TO_AFTER_LOGIN', '');
											window.location = config.appUrl + '/App/#/' + tempRedirectTo;
										}

									}//e:if:else
								});//e:AS_StoreSyncData
								
							}).catch((error2) =>{
								
								this.$store.commit('loaders/REMOVE_ID', 'App');

								console.log('error2');
								console.log(error2);
							});//e:ApiS_MakeCall


						}//e::if:else:config.isDev

					});//e:AS_SyncData
					
				}).catch((ApiS_MakeCallError)=>{
					console.log('ApiS_MakeCallError');
					console.log(ApiS_MakeCallError);

					if(this.currentLoginAttempt >= MAX_LOGIN_ATTEMPTS_BEFORE_SHOWING_MESSAGE){
						this.$store.commit('modals/OPEN', 'TroubleLoggingIn')
					}

					this.$store.commit('loaders/REMOVE_ID', 'App');
					this.isLoginError = true;
				});//e:ApiS_MakeCall

			}).catch((AS_ValidateFormError)=>{
				console.log('AS_ValidateFormError');
				console.log(AS_ValidateFormError);
				return false;
			});//e:AS_ValidateForm
		},//e:btnLogin

		slideNext(){
			if(this.currentSlide === this.maxSlides){
				this.currentSlide = 1;
			} else {
				this.currentSlide++;
			}
		},//e:slideNext

		slidePrev(){
			if(this.currentSlide === 1){
				this.currentSlide = this.maxSlides;
			} else {
				this.currentSlide--;
			}
		},//e:slidePrev

	},
	mounted() {
		// zero out your api token to simulate logged out
		// this.$store.commit('api/SET_TOKEN', '');
		// console.log(this.$store.state.api.token);

		this.$store.commit('loaders/REMOVE_ID', 'App');
		
		this.currentLoginAttempt = 0;
		
		/* 2023.08.18
		- after moving servers something weird is up with redirects
		*/

		if(this.$store.state.api.token === ''){
			// blank token - user isn't logged in yet (keep them here)
			this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PCOM);
		
			// force remove any theme classes - this view will not have a customer theme
			document.documentElement.setAttribute('data-bs-theme', 'light')
			document.body.classList.remove('theme-serrena');

			this.$store.commit('forms/ADD_FORM_TO_LIST',{
				formId: this.zFormId
			});
	
			// startup testimonial slideshow
			this.stoSlide = setInterval(()=>{
				this.slideNext();
			}, 5000);

		} else {
			// there is an api token, so the user shouldn't have to log in again

			if(config.isDev){
				// don't jam me up with tokens and cookies, i'm local so go right to the dashbaord
				this.$router.push({
					name:"Dashboard"
				}).catch(()=>{});

			} else {
				this.$store.commit('loaders/ADD_ID', 'App');

				// there is an API token but with redirect weirdness lets make sure to get another form token here (this only works with MVC but is temporary so it's ok)
				ApiS_MakeCall({
					method: 'GET',
					// ignoreAuth: true,
					outsideSpa: true,
					responseType: 'json',
					url: 'Forms/Login',
				}).then((responseData2)=>{
					// console.log('Forms/Login responseData2');
					// console.log(responseData2);
					
					AS_StoreSyncData(responseData2).then(()=>{
						if(this.$store.state.customer.expirationDate && (new Date(this.$store.state.customer.expirationDate) < config.today)){
							// Account Expired
							window.location = config.appUrl + '/Public/#/settings/my-profile';

						// } else if(responseData2.customer.isCancelled){
							// Account Canceled
							// window.location = config.appUrl + '/App/#/my-profile';

						}  else {
							// Account is Fine

							// are there any redirects stored i should go to instead?
							// if(this.$store.state.redirectToAfterLogin === ''){
							// 	// i have to use a hard redirect here to get from Public to App
							// 	window.location = config.appUrl + '/App/#/dashboard';
							// } else {
							// 	// use the redirect
							// 	let tempRedirectTo = this.$store.state.redirectToAfterLogin;
							// 	this.$store.commit('SET_REDIRECT_TO_AFTER_LOGIN', '');
							// 	window.location = config.appUrl + '/App/#/' + tempRedirectTo;
							// }
							
							// use a hard redirect here to get from Public to App
							window.location = config.appUrl + '/App/#/dashboard';

							// this.$router.push({
							// 	name: 'Dashboard'
							// }).catch(()=>{});

						}//e:if:else
					});//e:AS_StoreSyncData
					
				}).catch((error2) =>{
					
					this.$store.commit('loaders/REMOVE_ID', 'App');

					console.log('error2');
					console.log(error2);
				});//e:ApiS_MakeCall
			}//e:if:isDev

		}
	},
	components: {
		FormInput,
		FormPassword,
		ModalTroubleLoggingIn,
		PriceGrid,
		TestimonialRow
	}
}
</script>

<style lang="scss" scoped>
	@import "../assets/styles/vars_perrla";
	
	.hero {
		padding-top: 115px;
		padding-bottom: 115px;

		margin-left: -$grid-half-gutter;
		margin-right: -$grid-half-gutter;

		p {
			font-size: 1.2rem;
			font-weight: 400;
			line-height: 2.2rem;
			margin: 0 auto 8px auto;
				width: 75%;
		}

		ul{
			margin: 24px auto 0 auto;
			width: 50%;

			li {
				font-size: 19.2px;
				font-weight: 400;
				line-height: 36px;
				margin-bottom: 16px;
				text-align: left;
			}	
		}


		// Content Text Color
		h1, p {
			color:#fff;	
		}
		p {
			max-width:900px;
		}
		a.hero-link {
			color:#fff;
		}
		.btn {
			text-shadow: none;
		}
	
		

		// Content Text Shadow
		h1,p,a.hero-link {
			text-shadow: 0 0 0.5rem $body-color;
		}

	}



</style>