<!--
- Generic Modal > Trouble Loggin In 
-->

<template>
	<generic-modal-wrap modal-id="TroubleLoggingIn" :is-generic-modal="false">
		<h2 class="nodal-title">
			Having trouble logging in?
		</h2>

		<p class="">
			Don't fret! You've got a few options to get back into your PERRLA account.
		</p>
		<p class="">
			<strong>1. Make sure you're using the correct email address.</strong>
		</p>

		<p class="">
			<strong>2. Send yourself an email to reset your password.</strong><br />

			<router-link
				:to="{
					name: 'ForgotPassword'
				}">Click here to have a password reset link emailed to you automatically.</router-link> Be sure to check your spam if you don't see it in your inbox.
		</p>

		<p class="">
			<strong>3. Reach out to our Support Team.</strong><br />
			You can <a href="mailto:support@perrla.com?subject=Trouble logging in">email our team</a> for help every day until 10 pm Central.
		</p>

		<button class="btn btn-success" @click.prevent="$store.commit('modals/CLOSE', 'TroubleLoggingIn')">Sounds good</button>

	</generic-modal-wrap>
</template>

<script>
// import config from '@/config';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';

export default {
	name: 'ModalTroubleLoggingIn',
	data() {
		return {
		}
	},
	computed:{
	},
	methods:{
	},
	mounted() {

	},
	components:{
		GenericModalWrap
	},
}
</script>


<style lang="scss" scoped>
	
	@import "../../../assets/styles/_vars_perrla.scss";
	
</style>
