var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer" }, [
    _c(
      "div",
      { staticClass: "drawer-header" },
      [
        _c("tool-drawer-title", { attrs: { title: "Research Notes" } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-grid mt-2" },
          [
            _c("router-link", {
              attrs: {
                to: {
                  name: "EditorAddFromLibraryInResearchNotes",
                },
                custom: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ navigate }) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { role: "button", type: "button" },
                          on: {
                            click: navigate,
                            keypress: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return navigate.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\tAdd Reference from Library\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.filteredReferencesInPaper.length > 0
          ? _c("div", { staticClass: "d-flex justify-content-between mt-2" }, [
              _c("label", [_vm._v("Search")]),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "small hover-underline text-body",
                    attrs: {
                      href: "#",
                      "data-bs-toggle": "dropdown",
                      role: "button",
                      "aria-expanded": "false",
                      id: "dropdownMenuResearchNotes",
                    },
                  },
                  [
                    _c("span", { staticClass: "dropdown-toggle" }, [
                      _vm._v("Sorted By: "),
                      _c("span", { staticClass: "fw-bold" }, [
                        _vm._v(_vm._s(_vm.sortModeTextOutput)),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass: "dropdown-menu dropdown-menu-end",
                    attrs: { "aria-labelledby": "dropdownMenuResearchNotes" },
                  },
                  [
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item hover hover-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setSortBy(
                                _vm.$enums.ResearchNotesSortBy
                                  .CREATED_DATE_NEWEST_FIRST
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tCreated Date (newest first)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item hover hover-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setSortBy(
                                _vm.$enums.ResearchNotesSortBy
                                  .CREATED_DATE_OLDEST_FIRST
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tCreated Date (oldest first)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item hover hover-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setSortBy(
                                _vm.$enums.ResearchNotesSortBy
                                  .CITATION_FIRST_TO_LAST
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tCitation (first to last)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item hover hover-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setSortBy(
                                _vm.$enums.ResearchNotesSortBy
                                  .CITATION_LAST_TO_FIRST
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\tCitation (last to first)\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.filteredReferencesInPaper.length > 0
          ? _c("div", { staticClass: "d-flex mt-1" }, [
              _c("div", { staticClass: "w-100" }, [
                _c("input", {
                  directives: [
                    {
                      name: "debounce",
                      rawName: "v-debounce:500ms",
                      value: _vm.calcGridData,
                      expression: "calcGridData",
                      arg: "500ms",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchTerm,
                      expression: "searchTerm",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "Search research notes", type: "text" },
                  domProps: { value: _vm.searchTerm },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchTerm = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.searchTerm.length > 0,
                      expression: "searchTerm.length > 0",
                    },
                  ],
                  staticClass: "flex-shrink-1",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link hover-underline",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.clearSearch.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\tClear\n\t\t\t\t")]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "drawer-body" }, [
      _vm.filteredReferencesInPaper.length > 0
        ? _c("div", { key: "divResearchNotesReferenceInPaper" }, [
            _vm.displayedReferences.length > 0
              ? _c(
                  "div",
                  {
                    key: "divSearchResults",
                    staticClass: "list-of-research-notes",
                  },
                  _vm._l(_vm.displayedReferences, function (referenceObject) {
                    return _c("reference-row", {
                      key: referenceObject.referenceUniqueID,
                      attrs: {
                        "reference-unique-id":
                          referenceObject.referenceUniqueID.toUpperCase(),
                        "reference-object": referenceObject,
                        "search-term": _vm.searchTerm,
                        "sort-by-enum": _vm.sortByEnum,
                      },
                      on: {
                        "update-grid": _vm.calcGridData,
                        "set-sort-by-date-newest-first":
                          _vm.setSortByDateNewestFirst,
                      },
                    })
                  }),
                  1
                )
              : _c("div", { key: "divNoSearchResults" }, [
                  _c(
                    "p",
                    { staticClass: "fw-light org-size pt-2 text-center" },
                    [
                      _vm._v(
                        "\n\t\t\t\t\tNo results - try searching for something else\n\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
          ])
        : _c("div", { key: "divReserachNotesNoReferencesInPaper" }, [
            _vm._m(0),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-empty mt-2" }, [
      _c("p", [
        _vm._v(
          "It looks like you don't have any references added to this paper yet. To access the Research Notes saved in a reference, you need to add it to your paper first."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "You can add references to your paper using the References & Citations panel or using the button above."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }