var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex flex-row mb-2" },
        [
          _c("h1", { staticClass: "float-start me-3 mb-0" }, [
            _vm._v("\n\t\t\tReferences\n\t\t"),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-success float-start me-3",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnCreateReference.apply(null, arguments)
                },
              },
            },
            [
              _c("span", { staticClass: "svg bi-plus-lg me-1" }),
              _vm._v(" Create a new reference\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isReferenceOptionsOpen,
                  expression: "!isReferenceOptionsOpen",
                },
              ],
              staticClass: "dropdown float-end",
            },
            [
              _c(
                "a",
                {
                  staticClass: "btn-dropdown dropdown-toggle",
                  attrs: { href: "#", role: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnToggleReferenceOptionsOpen.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [_vm._v("\n\t\t\t\tShow Options\n\t\t\t")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isReferenceOptionsOpen,
                  expression: "isReferenceOptionsOpen",
                },
              ],
              staticClass: "dropdown float-end dropup",
            },
            [
              _c(
                "a",
                {
                  staticClass: "btn-dropdown dropdown-toggle",
                  attrs: { href: "#", role: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.btnToggleReferenceOptionsOpen.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [_vm._v("\n\t\t\t\tHide Options\n\t\t\t")]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex-fill" }, [
            _c("div", { staticClass: "dropdown float-end" }, [
              _c(
                "a",
                {
                  staticClass: "btn-dropdown dropdown-toggle me-2",
                  attrs: {
                    href: "#",
                    role: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-expanded": "false",
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.preferredFormatVersionIdOutput) +
                      "\n\t\t\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c("ul", { staticClass: "dropdown-menu dropdown-menu-end" }, [
                _c("li", [
                  _c(
                    "a",
                    {
                      class: [
                        "dropdown-item",
                        {
                          "dropdown-item-selected":
                            _vm.$store.state.references
                              .displayFormatVersionId ===
                            _vm.$enums.Format.APA7,
                          "hover hover-primary":
                            _vm.$store.state.references
                              .displayFormatVersionId !==
                            _vm.$enums.Format.APA7,
                        },
                      ],
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setPreferredFormatVersionId(
                            _vm.$enums.Format.APA7
                          )
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\t\tAPA 7\n\t\t\t\t\t\t")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      class: [
                        "dropdown-item",
                        {
                          "dropdown-item-selected":
                            _vm.$store.state.references
                              .displayFormatVersionId ===
                            _vm.$enums.Format.MLA9,
                          "hover hover-primary":
                            _vm.$store.state.references
                              .displayFormatVersionId !==
                            _vm.$enums.Format.MLA9,
                        },
                      ],
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setPreferredFormatVersionId(
                            _vm.$enums.Format.MLA9
                          )
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\t\tMLA 9\n\t\t\t\t\t\t")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      class: [
                        "dropdown-item",
                        {
                          "dropdown-item-selected":
                            _vm.$store.state.references
                              .displayFormatVersionId ===
                            _vm.$enums.Format.Turabian9,
                          "hover hover-primary":
                            _vm.$store.state.references
                              .displayFormatVersionId !==
                            _vm.$enums.Format.Turabian9,
                        },
                      ],
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setPreferredFormatVersionId(
                            _vm.$enums.Format.Turabian9
                          )
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\t\tTurabian 9\n\t\t\t\t\t\t")]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("grid-search", {
            staticClass: "ms-auto col-4",
            attrs: { "placeholder-text": "Search My References" },
            on: { "update-grid": _vm.calcGridData },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "slide-up-down",
        { staticClass: "pb-1", attrs: { active: _vm.isReferenceOptionsOpen } },
        [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isReferenceOptionsOpen,
                  expression: "isReferenceOptionsOpen",
                },
              ],
              staticClass: "btn btn-sm btn-outline-primary border-0",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnSelectAllReferences.apply(null, arguments)
                },
              },
            },
            [
              _c("span", { staticClass: "svg bi-plus" }),
              _vm._v(" Select All\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isReferenceOptionsOpen &&
                    (_vm.selectedGenesisReferenceIds.length > 0 ||
                      _vm.selectedLegacyReferenceIds.length > 0),
                  expression:
                    "isReferenceOptionsOpen && (selectedGenesisReferenceIds.length > 0 || selectedLegacyReferenceIds.length > 0)",
                },
              ],
              staticClass: "btn btn-sm btn-outline-primary border-0",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnClearSelectedReferences.apply(null, arguments)
                },
              },
            },
            [
              _c("span", { staticClass: "svg bi-x-lg" }),
              _vm._v(" Clear Selection\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isReferenceOptionsOpen && !_vm.isShowArchivedReferneces,
                  expression:
                    "isReferenceOptionsOpen && !isShowArchivedReferneces",
                },
              ],
              class: [
                "btn btn-sm btn-outline-danger border-0",
                {
                  "perrla-disabled":
                    _vm.selectedGenesisReferenceIds.length === 0 &&
                    _vm.selectedLegacyReferenceIds.length === 0,
                },
              ],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnArchiveSelectedReferences.apply(null, arguments)
                },
              },
            },
            [
              _c("span", { staticClass: "svg bi-dash" }),
              _vm._v(" Archive Selected References\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isReferenceOptionsOpen && _vm.isShowArchivedReferneces,
                  expression:
                    "isReferenceOptionsOpen && isShowArchivedReferneces",
                },
              ],
              class: [
                "btn btn-sm btn-outline-success border-0",
                {
                  "perrla-disabled":
                    _vm.selectedGenesisReferenceIds.length === 0 &&
                    _vm.selectedLegacyReferenceIds.length === 0,
                },
              ],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnRestoreSelectedReferences.apply(null, arguments)
                },
              },
            },
            [
              _c("span", { staticClass: "svg bi-plus" }),
              _vm._v(" Restore Selected References\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isReferenceOptionsOpen,
                  expression: "isReferenceOptionsOpen",
                },
              ],
              class: [
                "btn btn-sm btn-outline-danger border-0",
                {
                  // 'perrla-disabled' : selectedGenesisReferenceIds.length > 0 || selectedLegacyReferenceIds.length > 0
                },
              ],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.btnToggleShowArchived.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isShowArchivedReferneces,
                      expression: "!isShowArchivedReferneces",
                    },
                  ],
                },
                [_vm._v("Show ")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowArchivedReferneces,
                      expression: "isShowArchivedReferneces",
                    },
                  ],
                },
                [_vm._v("Hide ")]
              ),
              _vm._v("\n\t\t\tArchived References\n\t\t"),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            ref: "divReferenceListWrp",
            staticClass: "col-12 min-height overflow-y-auto position-relative",
          },
          [
            _vm.filteredGridData.length > 0
              ? _c(
                  "table",
                  {
                    staticClass:
                      "table table-borderless entity-grid table-fixed-header",
                  },
                  [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("sortable-grid-th", {
                            attrs: {
                              "cell-text": "Title",
                              colspan: 2,
                              "sort-key": "title",
                              "is-local-storage": true,
                              "is-title": true,
                              "entity-id": _vm.entityId,
                            },
                            on: { "update-grid": _vm.calcGridData },
                          }),
                          _vm._v(" "),
                          _c("th", { staticClass: "table-spacer" }),
                          _vm._v(" "),
                          _c("sortable-grid-th", {
                            attrs: {
                              "cell-text": "Created",
                              "set-width": "175px",
                              "sort-key": "createdDate",
                              "is-local-storage": true,
                              "is-title": true,
                              "entity-id": _vm.entityId,
                            },
                            on: { "update-grid": _vm.calcGridData },
                          }),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "30px" } }, [
                            _vm._v("\n\t\t\t\t\t\t\t \t"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.filteredGridData, function (reference, index) {
                        return _c("reference-row", {
                          key: index,
                          attrs: { "reference-data": reference },
                          on: {
                            "add-reference-selection":
                              _vm.addReferenceSelection,
                            "remove-reference-selection":
                              _vm.removeReferenceSelection,
                            "update-grid": _vm.calcGridData,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                )
              : _c("div", { staticClass: "text-muted text-center mt-5" }, [
                  _c("p", [_vm._v("No references found.")]),
                ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("route-modal"),
      _vm._v(" "),
      _c("modal-archive-all-references"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }