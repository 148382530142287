<!--
- Transaction Preview
-- component on Cart > Step 2 & 3
-->

<template>
	<div>
		<h3 class="mt-0" v-if="isShowRenewalText" key="h3IsShowRenewalText">
			Subscription Preview 
			<div class="help-text">
				These changes will be applied to your next subscription renewal on {{ calcExpirationDateOutput }}. 
			</div>
		</h3>

		<h3 class="mt-0" v-else key="h3IsShowRenewalTextElse">
			Transaction Preview
		</h3>
		
		<div
			v-if="isPrioritySupportOnly"
			key="divSummaryPSOnly">

			<div class="row mb-2">
				<div class="col">
					<h4 class="text-body">
						Priority Support
					</h4>
				</div>
				<div class="col">
					<h4 class="text-body text-end">
						{{ calcProRatedPrioritySupportPrice }}
					</h4>
				</div>
			</div> 

			<div class="row">
				<div class="col">
					<div class="help-text ms-3">
						<strong>Priority Support has been pro-rated for your current subscription.</strong> If you renew your subscription, Priority Support will be included at the standard rate {{ calcRateDisplay }}.
					</div>
				</div>
			</div>

		</div>
		<div
			v-else
			key="divSummaryPSElse">

			<div class="row">
				<div class="col">
					<h4 class="text-body">
						PERRLA Subscription
					</h4>
				</div>
				<div class="col">
					<h4 class="text-body text-end">
						{{ calcSubscriptionPriceOutput }}
					</h4>
				</div>
			</div>

			<div class="help-text ms-3">
				Your subscription includes:<br /> 
				- PERRLA for Word - our Word Add-in<br /> 
				- PERRLA Online - our web-based editor<br /> 
				- PERRLA Google Chrome Extension<br /> 
				- Legendary US-Based Customer Support
			</div>

			<!-- Priority Support row - if selected -->
			<div class="row" v-if="$store.state.cart.prioritySupport ">
				<div class="col">
					<h4 class="text-body">
						Priority Support
					</h4>
				</div>
				<div class="col">
					<h4 class="text-body text-end">
						{{ calcPrioritySupportPriceOutput }}
					</h4>
				</div>
			</div> 

			<promo-code v-if="isShowPromoCode" />
			
		</div>

		<div class="divider w-100 my-4" />

		<table class="table table-borderless table-cart-totals">
			<tbody>
				<tr>
					<td class="fw-light">
						Subtotal
					</td>
					<td class="fw-light text-end">
						{{ calcSubTotalDisplay }}
					</td>
				</tr>
				<tr>
					<td class="fw-light">
						Tax
					</td>
					<td class="fw-light text-end">
						{{ calcTaxDisplay }}
					</td>
				</tr>
			</tbody>
		</table>

		<div class="divider w-100 my-4" />

		<table class="table table-borderless table-cart-totals">
			<tbody>
				<tr>
					<td>
						Total
					</td>
					<td class="text-end">
						{{ calcTotalOutput }}
					</td>
				</tr>
			</tbody>
		</table>
		
	</div>
</template>

<script>
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import { addMonths, subMonths } from 'date-fns';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import currencyFormat from '@/helpers/currency';
import dateFormat from "dateformat";
import { differenceInMonths } from 'date-fns';
import PromoCode from './PromoCode';

export default {
	name: 'TransactionPreview',
	props:{
		isPrioritySupportOnly:{
			default: false,
			type: Boolean,
		},
	},
	data() {
		return {
			isShowPromoCode: false,
		}
	},
	computed:{
		calcPrioritySupportPriceOutput(){
			let returnText = '';
			if(!_isEmpty(this.$store.state.cart.products) && this.$store.state.cart.products.length >= 1 && _has(this.$store.state.cart.products[1], 'price')){
				returnText = currencyFormat({
					value: this.$store.state.cart.products[1].price
				});
				if(this.isShowRenewalText){
					returnText += ' at renewal'
				}
			}

			return returnText;
		},
		calcProRatedPrioritySupportPrice(){
			if(this.isPrioritySupportOnly){
				let numberOfMonths = differenceInMonths(new Date(this.$store.state.customer.expirationDate), new Date(config.today));

				// get account expiration date (ex March 1st, 2023)
				// calc how many months away that is (ex 2 months away, all Jan and all Feb ) 
				// apply the formulas above to that number of months ex $4)
				
				// $2 per month with $19.95 cap
				let proratedAmount = numberOfMonths * 2;
				if(proratedAmount > 19.95){
					proratedAmount = 19.95;
				} else if(proratedAmount < 2){
					proratedAmount = 2;
				}
				
				this.$store.commit('cart/SET_PRO_RATED_PRIORITY_SUPPORT_PRICE', proratedAmount);

				return currencyFormat({
					value: proratedAmount
				});
			}
			return '';
		},
		calcSubscriptionPriceOutput(){
			let returnText = '';

			if(!_isEmpty(this.$store.state.cart.products) && _has(this.$store.state.cart.products[0], 'price')){
				returnText = currencyFormat({
					value: this.$store.state.cart.products[0].price
				});
				if(this.isShowRenewalText){
					returnText += ' at renewal'
				}
			}
			return returnText;
		},
		calcSubTotalDisplay(){
			return currencyFormat({
				value: this.$store.state.cart.subTotalDisplay
			});
		},
		calcTaxDisplay(){
			return currencyFormat({
				value: this.$store.state.cart.tax
			});
		},
		calcTotalOutput(){
			let returnText = '';
			returnText = currencyFormat({
				value: this.$store.state.cart.grandTotal
			});

			if(this.isShowRenewalText){
				returnText += ' at renewal (' + this.calcExpirationDateOutput + ')';
			}
			return returnText;
		},
		calcExpirationDateOutput(){
			let expirationDate;	
			if(this.isExpired){
				// EXPIRED - based on today
				expirationDate = new Date();
			} else {
				// ACTIVE - based on the current exp date
				expirationDate = new Date(this.$store.state.cart.currentExpirationDate);
			}
			
			// what does the user have selected in their cart?
			if(this.renewalType === config.enums.SubscriptionRenewalType.AUTO){
				// Automatic Renewal
				if(this.isRenewal){
					if(this.isEditingSubscriptionSettigs){
						// user is editing subscription values only (they can only do this if this.isRecurring)

						// // customer WAS automatic 
						// if(this.$store.state.cart.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
						// 	expirationDate = subMonths(expirationDate, 6);
						// }
						
						// if(this.isExpired){
						// 	// this account was expired add 12 months no matter what(to cover the 6 months you may have jsut subtracted)
						// 	expirationDate = addMonths(expirationDate, 12);
						// }
						if(this.$store.state.cart.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
							expirationDate = addMonths(expirationDate, 6);
						} else {
							expirationDate = addMonths(expirationDate, 12);
						}
					} else {
						// user is extending their subscription - so we need to take some money
						// 	// if(!this.isRecurring || this.isExpired){
						if(this.$store.state.cart.renewalPeriod === config.enums.SubscriptionRenewalPeriod.ANNUAL){
							expirationDate = addMonths(expirationDate, 12);
						} else if(this.$store.state.cart.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
							expirationDate = addMonths(expirationDate, 6);
						}
						// 	}
					}
					return dateFormat(expirationDate, 'mmm') + '. ' + dateFormat(expirationDate, 'd') + ', ' + dateFormat(expirationDate, 'yyyy');
				} else {
					return this.$store.state.cart.expirationDateOutput;
				}
			} else if(this.renewalType === config.enums.SubscriptionRenewalType.MANUAL){
				// Manual Renewal
				if(this.isEditingSubscriptionSettigs){
					// user is editing subscription values only
					// customer WAS automatic 
					if(this.$store.state.cart.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
						expirationDate = subMonths(expirationDate, 6);
					}
					return dateFormat(expirationDate, 'mmm') + '. ' + dateFormat(expirationDate, 'd') + ', ' + dateFormat(expirationDate, 'yyyy');
					
				} else {
					// user is extending their subscription - so we need to take some money
					if(this.$store.state.cart.renewalPeriod === config.enums.SubscriptionRenewalPeriod.ANNUAL){
						expirationDate = addMonths(expirationDate, 12);
					} else if(this.$store.state.cart.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
						expirationDate = addMonths(expirationDate, 6);
					}
				}
				return dateFormat(expirationDate, 'mmm') + '. ' + dateFormat(expirationDate, 'd') + ', ' + dateFormat(expirationDate, 'yyyy');
			}
			return '';
		},

		calcRateDisplay(){
			let returnText = '';
			if(this.$store.state.customer.renewalPeriod){
				if(this.$store.state.customer.renewalPeriod === config.enums.SubscriptionRenewalPeriod.ANNUAL){
					let prioritySupportPrice = currencyFormat({
						value: this.$store.state.cart.prices.prioritySupportAnnualSubscription
					});
					returnText = '(' + prioritySupportPrice + ' for 12 months)';
				} else if(this.$store.state.customer.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
					let prioritySupportPrice = currencyFormat({
						value: this.$store.state.cart.prices.prioritySupportSemiAnnualSubscription
					});
					returnText = '(' + prioritySupportPrice + ' for 6 months)';
				}
			}
			return returnText;
		},
		
		isExpired(){
			if(this.$store.state.customer.expirationDate > config.today){
				return false;
			}
			return true;
		},
		isRecurring(){
			return this.$store.state.customer.isRecurring;
		},
		isRenewal(){
			return this.$store.state.cart.isRenewal;
		},
		isEditingSubscriptionSettigs(){
			if(this.isRenewal && this.isRecurring){
				return true;
			}
			return false;
		},
		isShowRenewalText(){
			// if(this.$store.state.cart.isRenewal && this.$store.state.customer.isRecurring && this.$store.state.customer.role === config.enums.Status.FREE_TRIAL){
			// this.$store.state.cart.renewalType === config.enums.SubscriptionRenewalType.AUTO &&
			if(this.$store.state.cart.isRenewal && !this.isExpired && this.isRecurring){
				return true;
			}
			return false;
		},

		renewalType:{
			get(){
				return this.$store.state.cart.renewalType;
			},
			set(newValue){
				this.$store.commit('cart/SET_RENEWAL_TYPE', newValue);
				this.$store.dispatch('cart/updateCartTotals');
			}
		},

	},
	mounted(){
		// Show Promo Code for:
		// - Free Trial (all)
		// - Manual Renewal (all)
		// - Automatic Renewals processed like manuals (no payment method)
		// - Expired accounts
		// Hide Promo Code for:
		// - Automatic Renewal Settings (payment method stored - not the Store)
		// - Adding priority support only

		if(
			this.$store.state.customer.role === config.enums.Status.FREE_TRIAL ||	
			!this.$store.state.customer.isRecurring ||	// NOT recurring means Manual Renewal
			this.isExpired
		){
			this.isShowPromoCode = true;

		} else {
			// check payment method
			ApiS_MakeCall({
				method: 'GET',
				responseType: 'json',
				url: 'v4/PaymentMethod',
			}).then((responseData)=>{
				// console.log('responseData v4/PaymentMethod');
				// console.log(responseData);
				if(_isEmpty(responseData)){
					// no payment method
					this.isShowPromoCode = true;
				}

				this.$store.commit('loaders/REMOVE_ID', 'App');

			}).catch(()=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
			});//e:ApiS_MakeCall
		}
	},
	components: {
		PromoCode,
	}
}
</script>
