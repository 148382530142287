var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isConvertTypeAvailable
      ? _c(
          "div",
          { key: "divYesConvertType" },
          [
            _c("h2", { staticClass: "nodal-title" }, [
              _vm._v(
                "\n                Review & update your reference\n            "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "org-size d-block" }, [
              _vm._v(
                "\n                Step 1 of 2: Check the existing data\n            "
              ),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            Object.keys(_vm.editReference).length != 0
              ? _c("reference-types", {
                  attrs: {
                    "reference-type-id": _vm.conversionResult.type,
                    "reference-rules-prop": _vm.conversionResult.data,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mt-2 row justify-content-between" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { key: "divModuleElse", staticClass: "d-grid" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.moduleModalBack.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Back\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col text-end" }, [
                _c("div", { staticClass: "d-grid" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.generateReference.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Next\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isConvertTypeAvailable
      ? _c("div", { key: "divNoConvertType" }, [
          _c("h3", [_vm._v("Problem updating this reference to APA 7")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Unfortunately, not every single APA 6 reference type from our legacy software can be upgraded to the new APA 7th Edition reference engine."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "While you may not be able to update this reference, you should be able to recreate it more easily than before. If the work has a DOI or ISBN, you can search for it directly when creating the new reference."
            ),
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.moduleModalBack.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n                    Back\n                ")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert alert-info" }, [
      _c("span", { staticClass: "svg bi-check-circle-fill float-start" }),
      _vm._v(" "),
      _c("p", { staticClass: "ps-3" }, [
        _vm._v(
          "\n                    Commonly used fields are outlined in blue.\n                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "If you have questions or need help, our Support Team is more than happy to help! You can reach them at "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "mailto:support@perrla.com?subject=Converting%20a%20legacy%20reference",
          },
        },
        [_vm._v("support@perrla.com")]
      ),
      _vm._v("."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }