var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "nodal" } }, [
    _vm.isGenericModal
      ? _c("div", { key: "divGenericModal" }, [
          _vm.isOpen
            ? _c(
                "div",
                { staticClass: "nodal-mask" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "nodal", mode: "out-in", appear: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "nodal-body" },
                        [
                          _vm.isCloseButton
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "hotkey",
                                      rawName: "v-hotkey",
                                      value: _vm.keymapCloseButton,
                                      expression: "keymapCloseButton",
                                    },
                                  ],
                                  staticClass: "btn-close",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.closeModal.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "svg bi-x-lg",
                                    attrs: {
                                      role: "button",
                                      "aria-label": "Close",
                                    },
                                  }),
                                ]
                              )
                            : _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "hotkey",
                                      rawName: "v-hotkey",
                                      value: _vm.keymapHiddenButton,
                                      expression: "keymapHiddenButton",
                                    },
                                  ],
                                  staticClass: "btn-close btn-close-hidden",
                                  attrs: { type: "button" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "svg",
                                    attrs: {
                                      role: "button",
                                      "aria-label": "Close",
                                    },
                                  }),
                                ]
                              ),
                          _vm._v(" "),
                          _vm._t("default"),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _c("div", { key: "divSpecialModal" }, [
          _vm.isOpen
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "nodal-body-special-modal" },
                  [
                    _vm.isCloseButton
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "hotkey",
                                rawName: "v-hotkey",
                                value: _vm.keymapCloseButton,
                                expression: "keymapCloseButton",
                              },
                            ],
                            staticClass: "btn-close",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.closeModal.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "svg bi-x-lg",
                              attrs: { role: "button", "aria-label": "Close" },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("default"),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }