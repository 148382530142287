<!--
- Paper Drawer > References & Citations
-- Body Only
-- Should load as the default drawer
-->

<template>
	<div class="drawer drawer-references-citations">
		<div class="drawer-header">
			<tool-drawer-title :title="calcPanelTitle" />

			<div class="d-grid mt-2 gap-2">
				<router-link
					:to="{
						name:'EditorCreateReferenceStep1',
						params: {
							openReferenceTab: 'common'
						}
					}"
					custom
					v-slot="{ navigate }">
					<button
						class="btn btn-success"
						role="button"
						type="button"
						@click="navigate"
						@keypress.enter="navigate">
						Create a New Reference
					</button>
				</router-link>

				<router-link
					:to="{
						name:'EditorAddFromLibrary',
					}"
					custom
					v-slot="{ navigate }">
					<button
						class="btn btn-success"
						role="button"
						type="button"
						@click="navigate"
						@keypress.enter="navigate">
						Add Reference from Library
					</button>
				</router-link>

				<!-- Hidden Search Field (sticky) -->
				<div class="d-flex mb-2" v-if="isSeachSticky">
					<div class="w-100">
						<input
							class="form-control"
							placeholder="Search your references"
							type="text"
							v-model="searchTerm" />
					</div>
					<div class="flex-shrink-1" v-show="searchTerm && searchTerm.length > 0">
						<button type="button" class="btn btn-link hover-underline" @click.prevent="searchTerm = ''">
							Clear
						</button>
					</div>
				</div>

			</div>
			
		</div><!-- drawer-header -->

		<div class="drawer-body" ref="refsCitsScrollDrawerBody">
			<transition name="route-fade">
				<div v-if="showRefDeleteWaring" class="close-warning">
					<h1>
						Remove this Reference?
					</h1>
					<p>
						Removing the Reference from your paper will also delete its citations in your paper.  Are you sure you want to remove this reference?
					</p>
					<div class="d-grid gap-2">
						<button type="button" class="btn btn-danger" @click.prevent="removeRefFromPaper">
							Remove Reference &amp; Delete Citations
						</button>
						<button type="button" class="btn btn-outline-primary" @click.prevent="showRefDeleteWaring = false">
							Cancel
						</button>
					</div>
				</div>
			</transition>

			<div v-if="filteredReferencesInPaper && filteredReferencesInPaper.length > 0" key="divReferencesV2InPaper" class="mb-2">
				<!-- Hidden Search Field (scroll) -->
				<div class="d-flex mb-2" v-if="isSearchScroll">
					<div class="w-100">
						<input
							class="form-control"
							placeholder="Search your references"
							type="text"
							v-model="searchTerm" />
					</div>
					<div class="flex-shrink-1" v-show="searchTerm && searchTerm.length > 0">
						<button type="button" class="btn btn-link hover-underline" @click.prevent="searchTerm = ''">
							Clear
						</button>
					</div>
				</div>
				<div ref="listOfReferencesWrp" class="list-of-references">
					<reference-row
						v-for="referenceObject in filteredReferencesInPaper"
						:key="referenceObject.referenceUniqueID"
						:reference-unique-id="referenceObject.referenceUniqueID.toUpperCase()"
						:reference-object="referenceObject" />
				</div>
			</div>
			<div v-else key="divNoReferencesInPaper" class="text-empty mt-5">
				<p>Create a new reference or add an existing one to your paper using the above buttons.</p>
				<p>You can create citations once you've added a reference to your paper.</p>
			</div>
		</div><!-- drawer-body -->
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import config from '@/config';
import PS_RemoveReferenceFromPaper from '@/services/paper/removeReferenceFromPaper';
import ReferenceRow from '@/views/Paper/TabEditor/ToolDrawer/ReferencesCitations/ReferenceRow';
import TDS_Open from '@/services/paper/toolDrawer/open';
import toBoolean from 'validator/lib/toBoolean';	//https://www.npmjs.com/package/validator
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'PaperDrawerReferencesCitations',
	data() {
		return {
			editReference: null,
			isSearchScroll: true,	// weird case, load this one by default and hide it 
			isSeachSticky: false,	// over 6 references in the library, make the search sticky
			showRefDeleteWaring: false,
			searchTerm: '',
		}
	},
	computed:{
		calcPanelTitle(){
			if(this.$store.state.paperEdit.referencesInPaper && this.$store.state.paperEdit.referencesInPaper.length > 0){
				return 'Refs & Citations <span class="fw-300">(' + this.$store.state.paperEdit.referencesInPaper.length + ')</span>';
			}
			return 'Refs & Citations';
		},
		filteredReferencesInPaper(){
			let searchedArray = [];
			let isTagSearch = false;
			let _data = _cloneDeep(this.$store.state.paperEdit.referencesInPaper);
			
			// console.log(_data);

			let _searchTerm = this.searchTerm && this.searchTerm.toLowerCase();

			if(typeof _searchTerm !== 'undefined' && _searchTerm.length > 0){
				if(_searchTerm.charAt(0) === '#'){
					isTagSearch = true;
					_searchTerm = _searchTerm.substring(1);
				} 
				_searchTerm = _searchTerm.toLowerCase();
			}
		
			// loop through each reference and prep for display
			_data.forEach((ref) =>{
				// skip references that have been deleted from the library
				// let isThisDeletedBoolean;
				// if(typeof ref.isDeleted === 'boolean'){
				// 	isThisDeletedBoolean = ref.isDeleted;
				// } else {
				// 	isThisDeletedBoolean = toBoolean(ref.isDeleted)
				// }

				// if(isThisDeletedBoolean){
				// 	return false;
				// }
				
				// console.log(ref);

				if(_has(ref, 'values')){
					let refValues = JSON.parse(ref.values);

					if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7){
						// APA7 
						if(_has(refValues, 'apa7') && _has(refValues.apa7, 'value')){
							ref.displayValue = refValues.apa7.value;
						}

					} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9){
						// MLA9
						if(_has(refValues, 'mla9') && _has(refValues.mla9, 'value')){
							ref.displayValue = refValues.mla9.value;
						}

					} else if(this.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9){
						// Turabian9
						if(_has(refValues, 'turabian9') && _has(refValues.turabian9, 'value')){
							ref.displayValue = refValues.turabian9.value;
						}
					}

					ref.formatVersionID = this.$store.state.paperEdit.meta.PaperFormatVersionID;
					ref.legacyReferenceData = '';

				} else {
					// Everything Else
					ref.displayValue = ref.value;
				}

				if(_searchTerm){
					if(isTagSearch){
						// search by tag
						if(ref.tags && ref.tags.toLowerCase().includes(_searchTerm)){
							searchedArray.push(ref);
						}

					} else {
						// search by all
						if(
							(ref.displayValue && ref.displayValue.toLowerCase().includes(_searchTerm)) ||
							(ref.legacyReferenceData && ref.legacyReferenceData.toLowerCase().includes(_searchTerm)) ||
							(ref.nickname && ref.nickname.toLowerCase().includes(_searchTerm)) ||
							(ref.tags && ref.tags.toLowerCase().includes(_searchTerm))
						){
							searchedArray.push(ref);
						}

					}
					
				} else {
					// Not searching just pass through
					searchedArray.push(ref);

				}//e:_searchTerm

				
			});//e:forEach
			
			// console.log(searchedArray);

			return searchedArray;
		},

		
	},
	methods: {
		removeRefFromPaper(){
			if(this.editReference){
				PS_RemoveReferenceFromPaper({
					hasActiveCitations: true,
					isDrawer: true,
					reference: this.editReference,
				}).then(()=>{
					this.showRefDeleteWaring = false;
					this.$store.commit('loaders/REMOVE_ID', 'App');
				});
			}
		},//e:removeRefFromPaper
		
		setPanelScroll(){
			this.$nextTick(()=>{
				// scroll the list of references
				if(this.$refs.listOfReferencesWrp){
					if(this.$refs.listOfReferencesWrp.scrollHeight > (this.$refs.refsCitsScrollDrawerBody.clientHeight)){
						if(this.filteredReferencesInPaper.length >=6){
							// https://trello.com/c/nb0SpYk4/389-qol-fix-search-my-references-box-to-the-top-of-the-panel
							// 6+ search becomes sticky
							this.isSeachSticky = true;
							this.isSearchScroll = false;
						} else {
							// 5 and under - search becomes scrollable
							this.isSeachSticky = false;
							this.isSearchScroll = true;
							if(this.$store.state.paperEdit.openReferenceUID === ''){
								this.$refs.listOfReferencesWrp.scrollIntoView(true);
							}
						}
					} else {
						this.isSeachSticky = false;
						this.isSearchScroll = false;
					}
				}

				_forEach(this.filteredReferencesInPaper, (reference)=>{
					// loop through each citation
					_forEach(reference.citations, (citation)=>{
						// to CitationRow.vue
						this.emitter.emit('checkIfCitationIsActive', {
							'citationUniqueId': citation.citationData.citationUniqueID.toUpperCase()
						});
					});//e:forEach
				});//e:forEach
			});
		},//e:setPanelScroll
	},
	created() {
		this.emitter.on('referencesInPaperReady', ()=>{
			this.setPanelScroll();
		});//e:referencesInPaperReady

		this.emitter.on('showRefDeleteWaring', ($opts)=>{
			window.scrollTo(0,0);
			this.editReference = $opts.reference;
			this.showRefDeleteWaring = true;
		});
	},
	mounted(){
		this.$store.commit('thirdPartyLookup/SEARCH_TERM', '');
		this.$store.commit('referenceRules/SET_RULES_OBJECT_DATA', []);
		
		TDS_Open({
			drawerName: 'EditorReferencesCitations'
		});

		this.$nextTick(()=>{
			this.setPanelScroll();
		});
	},
	destroyed(){
		this.emitter.off('referencesInPaperReady');
		this.emitter.off('showRefDeleteWaring');
	},
	components:{
		ReferenceRow,
		ToolDrawerTitle
	}
}
</script>
