<!--
- DuplicateReferenceConfirm
-
-->

<template>
	<div>
		<h3>You've already created this reference</h3>

		<div v-if="isReferenceInPaper" key="divDuplicateReferenceInPaper">
			<p>It looks like you already have this reference added to your paper.</p>
			<p>Would you like to cite the reference you've already added to your paper or create a new, duplicate reference?</p>
			<div class="d-grid gap-2 mb-2">
				<div class="d-grid">
					<button type="button" class="btn btn-success" @click.prevent="btnCiteExisitingReference">
						Use my existing reference
					</button>
				</div>

				<button 
					class="btn btn-outline-primary"
					@click.prevent="btnCreateDuplicateReference">
					Create duplicate reference
				</button>
			</div>
		</div><!--divDuplicateReferenceInPaper-->

		<div v-else key="divDuplicateReferenceNotInPaper">
			<p>We've found a reference in your PERRLA Library matching your search.</p>

			<p v-if="$router.currentRoute.meta.module === $enums.Module.REFERENCE_LIST">Would you like to create a duplicate reference?</p>

			<p v-else>Would you like to re-use the reference you've created before or create a new, duplicate reference?</p>	

			

			<div class="d-grid gap-2 mb-2">
				<!-- Disabled -->
				<p class="ps-2 mt-0 font-weight-bold incompatible-text" v-if="calcAddFromMode === $enums.AddFromLibraryMode.DISABLED" key="btnAddModeDisabled">
					<span class="svg bi-exclamation-lg" /> Incompatible Format
				</p>

				<!-- Coming Soon -->
				<p class="ps-2 mt-0 font-weight-bold incompatible-text" v-if="calcAddFromMode === $enums.AddFromLibraryMode.COMMING_SOON" key="btnAddModeComingSoon">
					<span class="svg bi-exclamation-lg" /> Reference type coming soon
				</p>
				
				<!-- Normal -->
				<div class="d-grid" v-else-if="calcAddFromMode === $enums.AddFromLibraryMode.NORMAL" key="btnAddModeNormal">
					<button type="button" class="btn btn-success" @click.prevent="btnUseMyExisitingReference">
						Use my exisiting reference
					</button>
				</div>

				<!-- Nevermind / Close Modal -->
				<div class="d-grid" v-else-if="calcAddFromMode === $enums.AddFromLibraryMode.NEVERMIND" key="btnAddModeFormatNevermind">
					<button type="button" class="btn btn-outline-danger" @click.prevent="btnCloseModal">
						Nevermind
					</button>
				</div>

				<button 
					class="btn btn-outline-primary"
					@click.prevent="btnCreateDuplicateReference">
					Create duplicate reference
				</button>
			</div>

		</div><!--divDuplicateReferenceNotInPaper-->

	</div>
</template>

<script>
import config from '@/config';
import PS_AddReferenceToPaper from '@/services/paper/addReferenceToPaper';
import ReferenceTypesData from '@/services/reference/types';

export default {
	name: 'DuplicateReferenceConfirm',
	computed:{
        calcAddFromMode(){
			if(this.$router.currentRoute.meta.module === this.$enums.Module.REFERENCE_LIST){
				return  config.enums.AddFromLibraryMode.NEVERMIND
			} 
            return this.$store.state.referenceDuplicate.addFromMode;
        },
		isReferenceInPaper(){
			let findIndex = this.$store.state.paperEdit.referencesInPaper.findIndex((referenceData)=>{
				return referenceData.referenceUniqueID === this.$store.state.referenceDuplicate.referenceObject.referenceUniqueID
			});
			if(findIndex >= 0){
				return true;
			}
			return false;
		},
        outputFormatDisabledText(){
			let returnText = 'Reference unavailable in this format';
			return returnText;
		},
        refTypes(){
			return ReferenceTypesData.refTypes;
		},
	},
	methods: {
		btnCloseModal(){
			this.emitter.emit('closeRouteModal');
		},//e:btnCloseModal

		btnCiteExisitingReference(){
			this.$router.push({
				name: 'EditorCitationAdd',
				params: {
					referenceUid: this.$store.state.referenceDuplicate.referenceObject.referenceUniqueID.toUpperCase()
				},
			});
		},//e:btnCiteExisitingReference

        btnCreateDuplicateReference(){
			let referenceTypeData = this.refTypes.find((referenceTypeDataLoop)=>{
				return referenceTypeDataLoop.id == this.$store.state.referenceDuplicate.referenceObject.referenceTypeID;
			});
			if(referenceTypeData){
				// open this reference type
				if(this.$router.currentRoute.meta.module === this.$enums.Module.REFERENCE_LIST){
					this.$router.push({
						name: 'ReferenceListCreateReferenceStep3ThirdParty',
						params:{
							referenceTypeSlug: referenceTypeData.slug
						}
					}).catch(()=>{});

				} else if(this.$router.currentRoute.meta.module === this.$enums.Module.PAPER_EDIT){
					if(this.$router.currentRoute.meta.tab === this.$enums.Tab.EDITOR){
						this.$router.push({
							name:'EditorCreateStep3ThirdParty',
							params:{
								referenceTypeSlug: referenceTypeData.slug
							}
						}).catch(()=>{});
					} else if(this.$router.currentRoute.meta.tab === this.$enums.Tab.OUTLINE){
						this.$router.push({
							name: 'OutlineCreateStep3ThirdParty',
							params: {
								referenceTypeSlug: referenceTypeData.slug
							},
						}).catch(()=>{});
					} else if(this.$router.currentRoute.meta.tab === this.$enums.Tab.RESEARCH){
						this.$router.push({
							name: 'PaperTabResearchCreateReferenceStep3ThirdParty',
							params: {
								referenceTypeSlug: referenceTypeData.slug
							},
						}).catch(()=>{});
					}
				}//e:module
			}//e:if
		},//e:btnCreateDuplicateReference

        btnUseMyExisitingReference(){
			this.$store.commit('loaders/ADD_ID', 'App');

			PS_AddReferenceToPaper({
				isDrawer: true,
				reference: this.$store.state.referenceDuplicate.referenceObject
			}).then(()=>{
				this.$router.push({
					name: 'EditorReferencesCitations'
				}).catch(()=>{});
			});//e:PS_AddReferenceToPaper

		},//e:btnUseMyExisitingReference
	}
}
</script>
