<!--
- Legacy Paper > Tab Preview
-
-->

<template>
	<nav-tab-content :is-no-padding="true">

        <legacy-paper-ribbon />
		
		<iframe  
			:src="paperLinkSrc"
			width="100%"
			ref="iframePaperPreview" />

	</nav-tab-content>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';
import LegacyPaperRibbon from './Ribbon';
import mixinResizer from '@/mixins/resizer';
import NavTabContent from '@/components/NavTabContent';

export default {
	name: 'LegacyPaperTabPreview',
	mixins: [mixinResizer],
	data() {
		return {
			paperLinkSrc: '',
			rzRef: 'iframePaperPreview',
		}
	},
	mounted() {
		if(this.$store.state.legacyPaper.fileUrl === ''){
			// no file url has been set for this paper yet
			this.$store.commit('loaders/ADD_ID', 'App');

			// do a full sync here incase it's a dev testing a legacy create
			AS_SyncData().then(()=>{

				// open object is empty, fully load from the route
				
				// look paper up by the encryptedId param
				let findPaper = this.$store.state.paperList.list.find((paperData)=>{
					return paperData.encryptedID === this.$route.params.encryptedId
				});
				// console.log('findPaper');
				// console.log(findPaper);
				if(findPaper){
					this.$store.commit('legacyPaper/SET_OPEN_OBJECT', findPaper)

					ApiS_MakeCall({
						method: 'GET',
						params: {
							paperId: this.$store.state.legacyPaper.openObject.paperID,
							// platform: this.$store.state.legacyPaper.openObject.paperType,
							platform: 1, // just Online Papers here
							contentType: config.enums.DownloadPaperContentType.PDF
						},
						responseType: 'arraybuffer',
						url: 'v4/Paper',
					}).then((responseData)=>{
						// console.log('responseData');
						// console.log(responseData);
										
						let file = new Blob([responseData], {
							type: 'application/pdf'
						});
					
						// let fileURL = window.URL.createObjectURL(file) + '#toolbar=0&navpanes=0';
						let fileURL = window.URL.createObjectURL(file) + '#navpanes=0';

						this.$store.commit('legacyPaper/SET_FILE_URL', fileURL);
						// console.log(fileURL);

						this.paperLinkSrc = fileURL;

						this.$store.commit('loaders/REMOVE_ID', 'App');
						
						this.calcHeight();

						setTimeout(()=>{
							// For Firefox it is necessary to delay revoking the ObjectURL
							window.URL.revokeObjectURL(fileURL);

						}, 100);

					});//e:ApiS_MakeCall

				} else {
					if(config.useErrorLogger){
						this.$rollbar.error('TabPreview > Could not find paper by route.');
					} else {
						console.log('TabPreview > Could not find paper by route');
					}
				}

			});//e:AS_SyncData
		} else {
			// a file url has been set, just reuse that so i don't have to relook up this paper
			this.paperLinkSrc = this.$store.state.legacyPaper.fileUrl;
			this.calcHeight();

		}

	},
	components: {
        LegacyPaperRibbon,
        NavTabContent
	}
}
</script>

