var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "generic-modal-wrap",
    { attrs: { "modal-id": "TroubleLoggingIn", "is-generic-modal": false } },
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n\t\tHaving trouble logging in?\n\t"),
      ]),
      _vm._v(" "),
      _c("p", {}, [
        _vm._v(
          "\n\t\tDon't fret! You've got a few options to get back into your PERRLA account.\n\t"
        ),
      ]),
      _vm._v(" "),
      _c("p", {}, [
        _c("strong", [
          _vm._v("1. Make sure you're using the correct email address."),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {},
        [
          _c("strong", [
            _vm._v("2. Send yourself an email to reset your password."),
          ]),
          _c("br"),
          _vm._v(" "),
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "ForgotPassword",
                },
              },
            },
            [
              _vm._v(
                "Click here to have a password reset link emailed to you automatically."
              ),
            ]
          ),
          _vm._v(
            " Be sure to check your spam if you don't see it in your inbox.\n\t"
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", {}, [
        _c("strong", [_vm._v("3. Reach out to our Support Team.")]),
        _c("br"),
        _vm._v("\n\t\tYou can "),
        _c(
          "a",
          {
            attrs: {
              href: "mailto:support@perrla.com?subject=Trouble logging in",
            },
          },
          [_vm._v("email our team")]
        ),
        _vm._v(" for help every day until 10 pm Central.\n\t"),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-success",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$store.commit("modals/CLOSE", "TroubleLoggingIn")
            },
          },
        },
        [_vm._v("Sounds good")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }