
<!--
- Paper Drawer > Research Notes
-->

<template>
	<div class="drawer">
		<div class="drawer-header">
			<tool-drawer-title title="Research Notes" />
			
			<div class="d-grid mt-2">
				<router-link
					:to="{
						name:'EditorAddFromLibraryInResearchNotes',
					}"
					custom
					v-slot="{ navigate }">
					<button
						class="btn btn-success"
						role="button"
						type="button"
						@click="navigate"
						@keypress.enter="navigate">
						Add Reference from Library
					</button>
				</router-link>
			</div>
			
			<div class="d-flex justify-content-between mt-2" v-if="filteredReferencesInPaper.length > 0">
				<label>Search</label>

				<div class="dropdown">
					<a href="#" class="small hover-underline text-body" data-bs-toggle="dropdown" role="button" aria-expanded="false" id="dropdownMenuResearchNotes">
						<span class="dropdown-toggle">Sorted By: <span class="fw-bold">{{ sortModeTextOutput }}</span></span>
					</a>

					<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuResearchNotes">
						<li>
							<a class="dropdown-item hover hover-primary" href="#" @click.prevent="setSortBy($enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST)">
								Created Date (newest first)
							</a>
						</li>
						<li>
							<a class="dropdown-item hover hover-primary" href="#" @click.prevent="setSortBy($enums.ResearchNotesSortBy.CREATED_DATE_OLDEST_FIRST)">
								Created Date (oldest first)
							</a>
						</li>
						<li>
							<a class="dropdown-item hover hover-primary" href="#" @click.prevent="setSortBy($enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST)">
								Citation (first to last)
							</a>
						</li>
						<li>
							<a class="dropdown-item hover hover-primary" href="#" @click.prevent="setSortBy($enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST)">
								Citation (last to first)
							</a>
						</li>
					</ul>
				</div>

			</div><!--d-flex-->

			<div class="d-flex mt-1" v-if="filteredReferencesInPaper.length > 0">
				<div class="w-100">
					<input
						class="form-control"
						placeholder="Search research notes"
						type="text"
						v-debounce:500ms="calcGridData"
						v-model="searchTerm" />
				</div>
				<div class="flex-shrink-1" v-show="searchTerm.length > 0">
					<button type="button" class="btn btn-link hover-underline" @click.prevent="clearSearch">
						Clear
					</button>
				</div>
			</div>
		</div><!-- drawer-header -->
		
		<div class="drawer-body">
			<div v-if="filteredReferencesInPaper.length > 0" key="divResearchNotesReferenceInPaper">
				<div v-if="displayedReferences.length > 0" key="divSearchResults" class="list-of-research-notes">
					<reference-row
						v-for="referenceObject in displayedReferences"
						:key="referenceObject.referenceUniqueID"
						:reference-unique-id="referenceObject.referenceUniqueID.toUpperCase()"
						:reference-object="referenceObject"
						:search-term="searchTerm" 
						:sort-by-enum="sortByEnum"
						@update-grid="calcGridData"
						@set-sort-by-date-newest-first="setSortByDateNewestFirst" />
				</div>
				<div v-else key="divNoSearchResults">
					<p class="fw-light org-size pt-2 text-center">
						No results - try searching for something else
					</p>
				</div>
			</div>
			<div v-else key="divReserachNotesNoReferencesInPaper">
				<div class="text-empty mt-2">
					<p>It looks like you don't have any references added to this paper yet. To access the Research Notes saved in a reference, you need to add it to your paper first.</p>

					<p>You can add references to your paper using the References & Citations panel or using the button above.</p>
				</div>
			</div> 
		</div>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';
import ReferenceRow from './ResearchNotes/ReferenceRow';
import TDS_Open from '@/services/paper/toolDrawer/open';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'EditorResearchNotes',
	data() {
		return {
			listOfResearchNotes: [],	// create a local copy that you can "hydrate" on deman
			displayedReferences: [],
			filteredGridData: [],
			searchTerm: '',
			sortByEnum: config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST,
		}
	},
	computed:{
		filteredReferencesInPaper(){
			return this.$store.state.paperEdit.referencesInPaper.filter((reference)=>{
				return !reference.isDeleted;
			});
		},
		sortModeTextOutput(){
			switch(this.sortByEnum){
				case config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST:
					return 'Created Date (newest first)'
				case config.enums.ResearchNotesSortBy.CREATED_DATE_OLDEST_FIRST:
					return 'Created Date (oldest first)'
				case config.enums.ResearchNotesSortBy.CITATION_FIRST_TO_LAST:
					return 'Citation (first to last)';
				case config.enums.ResearchNotesSortBy.CITATION_LAST_TO_FIRST:
					return 'Citation (last to first)';
			}
			return ''
		},
	},
	methods: {
		calcGridData(){
			// console.log('calcGridData');

			this.$store.commit('loaders/ADD_ID', 'App');

			let _searchTerm = this.searchTerm && this.searchTerm.toLowerCase();

			if(_searchTerm){
				// searching
				this.displayedReferences = [];

				// loop trough each reference in this paper
				this.filteredReferencesInPaper.forEach((referenceData)=>{
					// console.log(referenceData.referenceUniqueID);
					let researchNotesForThisReference = this.listOfResearchNotes.filter((researchNoteData)=>{
						return researchNoteData.referenceUniqueID.toUpperCase() === referenceData.referenceUniqueID.toUpperCase();
					});
					// console.log(researchNotesForThisReference);

					// loop through all notes from this reference
					researchNotesForThisReference.forEach((researchNoteData)=>{
						if(researchNoteData.note.toLowerCase().includes(_searchTerm)){
							// search term found

							// has this reference already been added?
							if(!this.displayedReferences.some((currentReferenceData)=> currentReferenceData.referenceUniqueID === referenceData.referenceUniqueID)){
								this.displayedReferences.push(referenceData);
							}
						}
					});//e:forEach

				});//forEach

			} else {
				// not searching
				this.displayedReferences = this.filteredReferencesInPaper;
			}

			this.$store.commit('loaders/REMOVE_ID', 'App');
		},//e:calcGridData

		clearSearch(){
			this.searchTerm = '';
			this.calcGridData();
		},

		setSortByDateNewestFirst(){
			this.sortByEnum = config.enums.ResearchNotesSortBy.CREATED_DATE_NEWEST_FIRST;
		},
		
		setSortBy(setToEnum){
			this.sortByEnum = setToEnum;
			this.calcGridData();
		},//e:setSortBy
	},
	created() {
		this.emitter.on('loadDrawerResearchNotes', () => {
			this.calcGridData();
		});
	},
	mounted() {
		TDS_Open({
			drawerName: 'EditorResearchNotes'
		}).then(()=>{
			this.$store.commit('loaders/ADD_ID', 'EditorTabToolDrawer');
			
			AS_SyncData({
				isStealthSync: true
			}).then(()=>{
				// hydrate the research notes from the vuex store
				this.listOfResearchNotes = this.$store.state.researchNotes.list;

				this.calcGridData();
				this.$store.commit('loaders/REMOVE_ID', 'EditorTabToolDrawer');
			});//e:AS_SyncData
			
		});//e:TDS_Open
	},
	components: {
		ReferenceRow,
		ToolDrawerTitle
	}
}
</script>
