<!--
- Reference List > Modal > Create Reference Step 1
-->
<template>
	<div>
        <div v-if="isConvertTypeAvailable" key="divYesConvertType">
            <h2 class="nodal-title">
                Review &amp; update your reference
            </h2>
        
            <p class="org-size d-block">
                Step 1 of 2: Check the existing data
            </p>

            <div class="alert alert-info">
                <span class="svg bi-check-circle-fill float-start" />
                <p class="ps-3">
                    Commonly used fields are outlined in blue.
                </p>
            </div>

            <reference-types
                v-if="Object.keys(editReference).length != 0" :reference-type-id="conversionResult.type"
                :reference-rules-prop="conversionResult.data" />

            <div class="mt-2 row justify-content-between">
                <div class="col">
                    <div class="d-grid" key="divModuleElse">
                        <button type="button" class="btn btn-outline-primary" @click.prevent="moduleModalBack">
                            Back
                        </button>
                    </div>
                </div>
                <div class="col text-end">
                    <div class="d-grid">
                        <button type="button" class="btn btn-success" @click.prevent="generateReference">
                            Next
                        </button>
                    </div>
                </div>
            </div>
        
            
        </div>

        <div v-if="!isConvertTypeAvailable" key="divNoConvertType">
            <h3>Problem updating this reference to APA 7</h3>
			<p>Unfortunately, not every single APA 6 reference type from our legacy software can be upgraded to the new APA 7th Edition reference engine.</p>
			<p>While you may not be able to update this reference, you should be able to recreate it more easily than before. If the work has a DOI or ISBN, you can search for it directly when creating the new reference.</p>
			<p>If you have questions or need help, our Support Team is more than happy to help! You can reach them at <a href="mailto:support@perrla.com?subject=Converting%20a%20legacy%20reference">support@perrla.com</a>.</p>
            <div class="d-grid">
                <button class="btn btn-outline-primary" @click.prevent="moduleModalBack" type="button">
                    Back
                </button>
            </div>
        </div>

	</div>
</template>

<script>
import conversionEngine from '@/services/reference/LegacyConversions/LegacyConversion';
// import CreateReferenceStep1Body from '@/modules/CreateReferenceStep1/Body'
// import NavTab from '@/components/NavTab';
// import NavTabs from '@/components/NavTabs';
// import ReferenceSearchThirdParty from '@/modules/CreateReferenceStep1/SearchThirdParty';
import ReferenceTypes from '@/components/reference-rules-genesis/Types';
// import ReferenceTypeSearch from '@/modules/CreateReferenceStep1/Search';

export default {
	name: 'ReferenceListLegacyConversion',
	computed:{
        conversionResult(){
			// don't do anything if editReference is empty
			if(Object.keys(this.editReference).length === 0){
				return {
					// type: 0,
					// data: {},
				};
			}

            // this.$store.commit('paperEdit/SET_EDIT_REFERENCE', this.referenceData);
            // console.log('this.editReference');
            // console.log(this.editReference);

            let conversionResultReturn = conversionEngine.convert(this.editReference.legacyReferenceData);
            // some pending types don't have an easy convert feature - show a generic screen if so
			if(conversionResultReturn.type === 0){
				this.isConvertTypeAvailable = false;
			}

            this.$store.commit('referenceRules/SET_RULES_OBJECT_DATA', conversionResultReturn.data);
			this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', conversionResultReturn.type);
            
            return conversionResultReturn;
        },//e:conversionResult

        editReference(){
			return this.$store.state.referenceEdit.openObject;
		},
		refTypes(){
			return ReferenceTypes.refTypes;
		}
	},
    data() {
		return {
			isConvertTypeAvailable: true,
		}
	},
    methods:{
        generateReference(){
			this.$store.dispatch('referenceRules/generateReferencePreview').then(()=>{
				this.$store.dispatch('referenceRules/generateReference');
			});
		},//e:generateReference
        moduleModalBack(){
            this.emitter.emit('closeRouteModal');
        },
    },
	mounted() {
		if(this.$route.name === 'ReferenceListLegacyConversion'){
			this.emitter.emit('openRouteModal', {
				modalRoute: 'ReferenceListLegacyConversion',
			});

            // reloading the page without a row click, just close the modal
            if(Object.keys(this.editReference).length === 0){
                this.emitter.emit('closeRouteModal');
            }
		}
	},
	components: {
		ReferenceTypes,
	}
}
</script>
