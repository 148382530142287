<!--
- Route Modal
- used for any modals that change with the url route
- ex: paper/organize/reference-add-from-library
-->

<template>
	<transition name="nodal">
		<div class="nodal-mask" v-show="isOpen">
			<transition name="nodal" mode="out-in" appear>
				<div class="nodal-body">
					<button type="button" class="btn-close" @click.prevent="closeModal" v-hotkey="keymap">
						<span class="svg bi-x-lg" role="button" aria-label="Close" />
					</button>

					<router-view />
					
				</div>
			</transition>
		</div><!--nodal-mask-->

		<!-- <div class="modal-mask" v-show="isOpen">
			<div class="modal-wrp">
				<transition name="modal" mode="out-in" appear>
					<router-view />
				</transition>
			</div>
		</div> -->
	</transition>
</template>

<script>

export default {
	name: 'RouteModal',
	data() {
		return {
			isOpen: false,
		}
	},
	computed:{
		keymap(){
			return {
				'esc': () => {
					// creating research paper has a new beta feature to search for a class
					// if the user is inside there and presses ESC i just wnat to get out of the dropdown
					// for now just block that whole modal from closing
					// i think it's because 'v-hotkey' has a higher priority, deal with it later in Vue3 
					if(this.$route.name !== 'PaperListCreateResearchPaper'){
						this.closeModal();
					}
				},
			}
		},
	},
	methods: {
		_open(){
			this.isOpen = true;
		},
		closeModal(){
			this.isOpen = false;

			this.$store.commit('loaders/REMOVE_ID', 'App');

			let closeToRouteName = 'Dashboard';

			try {
				closeToRouteName = this.$route.matched[this.$route.matched.length - 2].name
			} catch {
				console.log('$route match error - default to Dashboard');
			}

			if(this.$route.name != 'Dashboard'){
				this.$router.push({
					name: closeToRouteName,
					params: this.$route.params,
				}).catch(()=>{});
			}
		},
	},
	created() {
		this.emitter.on('closeRouteModal', ()=>{
			this.closeModal();
		});
		
		this.emitter.on('openRouteModal',($opts)=>{
			// console.log('openRouteModal');
			// console.log($opts);

			if(this.$router.currentRoute.name != $opts.modalRoute){
				this._open();
				let modalParams = $opts.modalParams || {};
				this.$router.push({
					name: $opts.modalRoute,
					params: modalParams
				}).catch(()=>{});
			} else if(
				$opts.modalRoute === 'ClassListCreateClass' ||
				$opts.modalRoute === 'DashboardCreateWork' ||
				$opts.modalRoute === 'PaperListCreateAnnotatedBibliography' ||
				$opts.modalRoute === 'PaperListCreateDiscussionPost' ||
				$opts.modalRoute === 'PaperListCreateReferenceList' ||
				$opts.modalRoute === 'PaperListCreateResearchPaper' ||
				$opts.modalRoute === 'ReferenceListCreateReferenceStep1' ||
				$opts.modalRoute === 'ReferenceListLegacyConversion' ||
				$opts.modalRoute === 'ReminderListSingle'
			){
				// open the modal users may get here from outside the SPA
				this._open();
			}
		});
	},
	destroyed(){
		// these are resets or clears and should be done based on the open module modal
		this.emitter.off('closeRouteModal');
		this.emitter.off('openRouteModal');
	},
}
</script>

