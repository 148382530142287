<!--
- Paper Editor > Tool Drawer > References & Citations > Add From Library
-->
<template>
	<div class="drawer drawer-add-from-library">

		<div class="drawer-header">
			<tool-drawer-title
				title="Add Ref from Library"
				close-to-route-name="EditorReferencesCitations" />
			
			<add-reference-from-library-header
				v-if="isReferenceLibraryShown"
				:is-drawer="true" />
		</div>

		<div class="drawer-body">
			<add-reference-from-library-body :is-drawer="true" />
		</div>

		<div class="drawer-footer" v-if="isReferenceLibraryShown">
			<div class="d-grid">
				<router-link :to="{name:'EditorReferencesCitations'}" class="btn btn-success">
					Finished
				</router-link>
			</div>
		</div>

	</div><!--container-->
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import AddReferenceFromLibraryBody from '@/modules/AddReferenceFromLibrary/Body';
import AddReferenceFromLibraryHeader from '@/modules/AddReferenceFromLibrary/Header';
import TDS_Open from '@/services/paper/toolDrawer/open';
import ToolDrawerTitle from '@/components/ToolDrawer/Title';

export default {
	name: 'EditorAddReferenceFromLibrary',
	computed:{
		isReferenceLibraryShown(){
			if(_isEmpty(this.$store.state.referenceLibrary) && _isEmpty(this.$store.state.referenceLibraryGenesis)){
				return false
			}
			return true;
		},
	},
	components:{
		AddReferenceFromLibraryBody,
		AddReferenceFromLibraryHeader,
		ToolDrawerTitle
	},
	mounted(){
		TDS_Open({
			drawerName: 'EditorReferencesCitations'	// set a main drawer here, Add From library can't refresh
		});
	},
}
</script>

<style lang="scss" scoped>
	@import "./../../../../../assets/styles/vars_perrla.scss";

	@include color-mode(dark) {
		.drawer {
			.drawer-header{
				background-color: $dark-body-bg;
			}
			.drawer-footer {
				background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), $dark-body-bg 10%);
			}
		}
	}

	.drawer {
		.drawer-header{
			background-color: #fff;
		}
	
		.drawer-footer {
			background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 10%);
			padding: 0.75rem 0.5rem 0.5rem 0.5rem;	// same as mt-3 / m-2
		}

	}//.drawer

</style>
