<!--
- Organization Invite
-- this link is generated and sent via email as an invitation to join ths organization
-- user id will be base64 encoded
-->

<template>
	<div class="layout-pcom">
		<section class="section-text">
			<main class="container-text mt-2">
				<div class="white-inset w-75 mx-auto">
					<div class="inset-body">
					
						<div class="row">
							<div class="col-12 text-center">
								<img src="///perrla.s3.amazonaws.com/images/logo-p-small.png" class="leego-img" alt="PERRLA, LLC" />
								<h1>PERRLA</h1>
							</div>
						</div>

						<div v-if="hasAccount && isInvitationValid" key="divHasAccount">
							<h4>Welcome back, {{ firstName }}!</h4>

							<p>{{ organizationName }} would like support your existing PERRLA subscription (they want to pay for it)</p>

							<p>Log in below to connect your account.</p>
							<br />

							<form>
								<form-input
									label="Account Email"
									type="email"
									:form-id="zFormIdInvite"
									:validate-enum="$enums.Validation.EMAIL_REQUIRED"
									v-model="formEmail" />

								<form-input
									label="Password"
									type="password"
									:form-id="zFormIdInvite"
									:validate-enum="$enums.Validation.REQUIRED"
									v-model="formPassword" />

								<p class="link-forgot-password">
									<router-link
										:to="{
											name: 'ForgotPassword'
										}"
										class="hover-underline">
										Forgot password?
									</router-link>
								</p>
								
								<div class="d-grid gap-2 mt-3">
									<button
										class="btn btn-success"
										type="submit"
										@click.prevent="btnLogin">
										Login
									</button>

									<button
										class="btn btn-outline-primary"
										type="button"
										@click.prevent="btnSetHasAccount(false)">
										Create a new PERRLA Account
									</button>
								</div>
							</form>


						</div>

						<div v-else-if="!hasAccount && isInvitationValid" key="divNewAccount">
							<h4>Welcome, {{ firstName }}!</h4>

							<p>{{ organizationName }} has provided your basic account information. You can edit this after you create your account.</p>

							<p>Once you set your password below, you will use this email and your password to access PERRLA.</p>

							<p class="ms-4">
								<strong>Name:</strong> {{ firstName }} {{ lastName }}<br />
								<strong>Account Email:</strong> {{ email }}
							</p>
							<p>Create a password for your account to get started.</p>
							
							<div class="alert alert-danger" v-if="isPasswordError">
								Please enter matching passwords.
							</div>

							<form class="mt-2">
								<form-input
									label="Password"
									type="password"
									:form-id="zFormIdConfirmPassword"
									:validate-enum="$enums.Validation.REQUIRED"
									v-model="formPassword" />

								<form-input
									label="Confirm Password"
									type="password"
									:form-id="zFormIdConfirmPassword"
									:validate-enum="$enums.Validation.REQUIRED"
									v-model="formPasswordConfirm" />

								<p class="link-forgot-password">
									<a href="mailto:support@perrla.com?subject=My PERRLA invitation doesn't match" class="hover-underline">
										If this isn't you, click here to contact our Customer Support Team
									</a>
								</p>
								
								<div class="d-grid gap-2 mt-3">
									<button
										class="btn btn-success"
										type="submit"
										@click.prevent="btnCreateAccount">
										Create My Account
									</button>

									<button
										class="btn btn-outline-primary"
										type="button"
										@click.prevent="btnSetHasAccount(true)">
										I already have a PERRLA Account
									</button>
								</div>
							</form>
						</div>

						<div v-else-if="!isInvitationValid" key="divInvalidInvite">
							<h4>Oops! It looks like this invitation is no longer available.</h4>
							<p>If you think this is an error, please reach out to the school or organization that invited you to use PERRLA.</p>
							<p>If not, you can also reach out to our Customer Support Team for assistance.</p>
							<div class="d-grid mt-3">
								<a href="mailto:support@perrla.com" class="btn btn-outline-primary">
									Contact Customer Support
								</a>
							</div>
						</div>
					</div>
				</div>
			</main>
		</section>

		<section class="section-text">
			<main class="container-text mt-2">
				
				<div v-if="isInvitationValid" key="divQuestionsValid">
					<h2 class="text-center">
						Answers to a few common questions
					</h2>

					<h4>Why is my name and email already provided?</h4>
					<p class="small mb-5">
						When an organization invites you to use PERRLA, they have provided the initial information we need to set up your account. After you create your PERRLA&nbsp;account, you can edit your name in your PERRLA&nbsp;Settings.&nbsp;You can also add a secondary email address as well.
					</p>

					<h4>How did I&nbsp;get this?</h4>
					<p class="small mb-5">
						You received this invitation because your school or organization is providing PERRLA for you to help you write your upcoming papers.
					</p>

					<h4>What do I&nbsp;need to do now?</h4>
					<p class="small">
						All you need to do is enter a password for your account&nbsp;in the form above.
					</p>
					<p class="small mb-5">
						Once you set the password for your account, we&#39;ll provide instructions for how to get started with either our Microsoft Word Add-in or our web-based Editor.
					</p>

					<h4>What if I&#39;ve used PERRLA&nbsp;before?</h4>
					<p class="small">
						Not a problem! Instead of setting a new password, select the &quot;I&nbsp;already have a PERRLA&nbsp;Account&quot; button above. Then you can log into your existing PERRLA Account.
					</p>
					<p class="small mb-5">
						By using your existing account, you can keep all of your existing references AND your school or organization covers the cost of continuing to use PERRLA. It&#39;s really a win-win for you!
					</p>
				</div>
				<div v-else key="divQuestionsInvalid">
					<h4>Why doesn&#39;t my invitation work?</h4>
						<p class="small">
							There are a few reasons a PERRLA&nbsp;invitation may not work.
						</p>
						<p class="small">
							The invitation link may have already been used by someone else. Each person receives their own unique, one-time use link to create a PERRLA&nbsp;Account. Everyone needs their own invitation that can be only used once.
						</p>
						<p class="small mb-5">
							If this is the first time you&#39;re using this link for your invitation, you may need to reach out to your school for clarification. Each school or organization handles their PERRLA&nbsp;invitations directly.
						</p>
				</div>

				<h4>Have more questions?</h4>
				<p class="small">
					We have more answers!
				</p>
				<p class="small">
					Check out our <a href="https://perrla.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer">Help Center</a> for more articles and videos on how to use PERRLA. Or, reach out to our <a href="mailto:support@perrla.com" id="https-www.perrlacomplete.com-ContactSupport">Customer Support team</a> directly. They are available 7 days a week until 10pm Central.
				</p>
			</main>
		</section>

		<section class="section-text pt-0" v-if="isInvitationValid">
			<main class="container-text">
				<h2 class="text-center">
					Some more information about PERRLA
				</h2>
				<h2 class="text-center">
					A little more about PERRLA.Every PERRLA account includes PERRLA Online & PERRLA for Word!
				</h2>
				
				<price-grid />
			</main>
		</section>
	</div>
</template>

<script>
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
// import AS_FullLogout from '@/services/app/fullLogout';
import AS_StoreSyncData from '@/services/app/storeSyncData';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import PriceGrid from '@/components/PriceGrid';

export default {
	name: 'OrganizationInvite',
	data() {
		return {
			email: '',
			firstName: '',
			formEmail: '',
			formPassword: '',
			formPasswordConfirm: '',
			hasAccount: false,
			lastName: '',
			organizationName: '',

			isInvitationValid: true,
			isPasswordError: false,
			zFormIdInvite: 'formInvitePassword',
			zFormIdConfirmPassword: 'formInviteConfirmPassword',
		}
	},
	methods: {
		btnCreateAccount(){
			// quick validation to make sure the passwords match
			if(this.formPassword.length === 0 || this.formPasswordConfirm.length === 0){
				this.isPasswordError = true;
			} else {
				if(this.formPassword !== this.formPasswordConfirm){
					this.isPasswordError = true;
				} else {
					AS_ValidateForm({
						formId: this.zFormIdConfirmPassword
					}).then(()=>{

						// only valid version
						this.isPasswordError = false;

						// local development can't acccess the MVC project so don't even start down that path if you are local
						if(config.isDev){
							alert('Org Invite Activation not possible locally');
						
						} else {
							this.$store.commit('loaders/ADD_ID', 'App');

							// 1 - activate this user (create an account)
							ApiS_MakeCall({
								body: {
									'id': this.$route.params.encryptedUserId,
									'password': this.formPassword,
								},
								ignoreAuth: true,
								method: 'POST',
								url: 'v4/CohortUser/Activate',
								responseType: 'json',
							}).then((responseData)=>{
								// console.log('responseData');
								// console.log(responseData);

								// 2 - get an access token - this is a real login now
								ApiS_MakeCall({
									body: 'client_id=complete&grant_type=password&username=' + encodeURIComponent(responseData.email) + '&password=' + encodeURIComponent(this.formPassword),
									contentType: 'application/x-www-form-urlencoded',
									ignoreAuth: true,
									isHardUrl: true,
									method: 'POST',
									responseType: 'json',
									url: (config.isLive) ? 'https://auth.perrla.com/connect/token' : 'https://testauth.perrla.com/connect/token',
								}).then((responseData2)=>{
									// console.log('auth responseData2');
									// console.log(responseData2);
									this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
										formId: this.zFormIdConfirmPassword
									});

									// 3 - store the token in vuex
									this.$store.commit('api/SET_TOKEN', responseData2.access_token);

									// 4 - pass this token back to an MVC project (thats why i blocked it at the start if it's local)
									ApiS_MakeCall({
										method: 'GET',
										// ignoreAuth: true,
										outsideSpa: true,
										responseType: 'json',
										url: 'Forms/Login',
									}).then((responseData3)=>{
										console.log('responseData3');
										console.log(responseData3);
										
										this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);
										
										AS_StoreSyncData(responseData3).then(()=>{
											// // i have to use a hard redirect here to get from Public to App
											// window.location = config.appUrl + '/App/#/dashboard';
											window.location = config.appUrl + '/App/#/registration-success';
											
											// this.$router.push({
											// 	name: 'RegistrationSuccess'
											// }).catch(()=>{});
										});//e:AS_StoreSyncData
									}).catch((error2) =>{
										this.$store.commit('loaders/REMOVE_ID', 'App');
										console.log('error2');
										console.log(error2);
									});
								
								});

							});//e:ApiS_MakeCall
						}

					}).catch(()=>{
						return false;

					});//e:AS_ValidateForm
				}
			}
		},

		btnLogin(){
			AS_ValidateForm({
				formId: this.zFormIdInvite
			}).then(()=>{

				// local development can't acccess the MVC project so don't even start down that path if you are local
				if(config.isDev){
					console.log({
						formEmail: this.formEmail,
						formPassword: this.formPassword,
					});
					alert('Org Invite Activation not possible locally');

				} else {
				
					// 1 - get an access token - this is a real login now
					ApiS_MakeCall({
						body: 'client_id=complete&grant_type=password&username=' + encodeURIComponent(this.formEmail) + '&password=' + encodeURIComponent(this.formPassword),
						contentType: 'application/x-www-form-urlencoded',
						ignoreAuth: true,
						isHardUrl: true,
						method: 'POST',
						responseType: 'json',
						url: (config.isLive) ? 'https://auth.perrla.com/connect/token' : 'https://testauth.perrla.com/connect/token',
					}).then((responseData)=>{
						// console.log('auth responseData');
						// console.log(responseData);

						// 2 - store the token in vuex
						this.$store.commit('api/SET_TOKEN', responseData.access_token);

						this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
							formId: this.zFormIdInvite
						});

						// 3 - pass this token back to an MVC project (thats why i blocked it at the start if it's local)
						ApiS_MakeCall({
							method: 'GET',
							// ignoreAuth: true,
							outsideSpa: true,
							responseType: 'json',
							url: 'Forms/Login',
						}).then((responseData2)=>{
							// console.log('responseData2');
							// console.log(responseData2);

							// 4 - user is logged in now connect this account to the org
							ApiS_MakeCall({
								// ignoreAuth: true,
								method: 'POST',
								params:{
									id: this.$route.params.encryptedUserId,
								},
								responseType: 'json',
								url: 'v4/CohortUser/ActivateExisting',
							}).then((responseData3)=>{
								// console.log('responseData3');
								// console.log(responseData3);

								this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PO);
							
								AS_StoreSyncData(responseData2).then(()=>{
									// i have to use a hard redirect here to get from Public to App
									window.location = config.appUrl + '/App/#/dashboard';
								});//e:AS_StoreSyncData
							});

						}).catch((error2) =>{
							this.$store.commit('loaders/REMOVE_ID', 'App');
							console.log('error2');
							console.log(error2);
						});

					});//e:ApiS_MakeCall
				}

			}).catch(()=>{
				return false;
				
			});//e:AS_ValidateForm
		},
		
		btnSetHasAccount(setToValue){
			this.hasAccount = setToValue;
		},

		loadUI(){
			// get encypted user id from the $route and look this user up
			ApiS_MakeCall({
				method: 'GET',
				ignoreAuth: true,
				params:{
					id: this.$route.params.encryptedUserId,
				},
				url: 'v4/CohortUser/Activate',
				responseType: 'json',
			}).then((responseData)=>{
				// console.log('v4/CohortUser/Activate responseData');
				// console.log(responseData);

				// prefill data from the response
				this.email = responseData.email;
				this.firstName = responseData.firstName;
				this.hasAccount = false;
				this.isInvitationValid = true;
				this.lastName = responseData.lastName;
				this.organizationName = responseData.organization;	
				
				this.$store.commit('forms/ADD_FORM_TO_LIST',{
					formId: this.zFormIdConfirmPassword
				});

				this.$store.commit('forms/ADD_FORM_TO_LIST',{
					formId: this.zFormIdInvite
				});
				
				if(_has(this.$route.params, 'mode')){
					if(this.$route.params.mode === 'create'){
						this.hasAccount = false;
					} else {
						this.hasAccount = true;
					}
				} else {
					this.hasAccount = false;	
				}

				this.$store.commit('loaders/REMOVE_ID', 'App');

			}).catch((errorMessageReturn)=>{
				this.isInvitationValid = false;

				if(_has(errorMessageReturn, 'code') && errorMessageReturn.code === 'USER_ALREADY_CREATED'){
					this.$store.commit('loaders/REMOVE_ID', 'App');

					// user has an account and it's already linked to this org
					if(this.$store.state.api.token === ''){
						// user is not logged in, so just take them there
						this.$router.push({
							name: 'Login'
						}).catch(()=>{});

					} else {
						// there is an api token, so the user shouldn't have to log in again
						
						// use a hard redirect here to get from Public to App
						if(config.isDev){
							this.$router.push({
								name: 'Dashboatd'
							}).catch(()=>{});
						} else {
							window.location = config.appUrl + '/App/#/dashboard';
						}
						

					}//e:if
					
				} else {
					this.hasAccount = false;
					this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.SIMPLE);
					this.$store.commit('loaders/REMOVE_ID', 'App');

				}//e:if
			
				
			});//e:ApiS_MakeCall
		},//e:loadUI
	},
	mounted() {
		this.$store.commit('loaders/ADD_ID', 'App');

		
		
		this.loadUI();

		// // it's possible for a user to be logged in here?
		// if(this.$store.state.api.token === ''){
		// 	// user not logged in
		// 	this.loadUI();

		// } else {
		// 	// user logged in
		// 	AS_FullLogout().then(()=>{
		// 		// clean up layout / theme stuff
	
		// 		// force remove any theme classes - this view will not have a customer theme
		// 		document.documentElement.setAttribute('data-bs-theme', 'light')
		// 		document.body.classList.remove('theme-serrena');

		// 		this.loadUI();
		// 	});//e:AS_FullLogout
		// }
		

		
	},
	components: {
		FormInput,
		PriceGrid
	}
}
</script>
